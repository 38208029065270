import { Engine } from 'babylonjs';
import React, { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Options } from '../../App';
import { FarmScene } from '../../farmScene/farmScene';
import styles from './Canvas.module.scss';
import * as Hub from '../../messaging/hub';
import { FloatingButtonGroup, FloatingButtonsPanel } from './floatingButtonsPanel/FloatingButtonsPanel';
import { ImmersiveModeButton } from './floatingButtonsPanel/ImmersiveModeButton';
import { VRButton } from './floatingButtonsPanel/VRButton';
import { FullScreenButton } from './floatingButtonsPanel/FullScreenButton';
import { ResetPositionButton } from './floatingButtonsPanel/ResetPositionButton';
import { RotateYardButton } from './floatingButtonsPanel/RotateYardButton';
import { saveToHash } from '../../persistence';

interface ICanvas {
    setLoadingWorldState: React.Dispatch<React.SetStateAction<boolean>>;
    setLoadingYardState: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Canvas: React.FC<ICanvas> = (props) => {
    const canvas: MutableRefObject<HTMLCanvasElement | null> = useRef(null);
    const canvasContainer: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const options = useContext(Options);
    const [world, setWorld] = useState<FarmScene | null>(null);
    const [previousOptions, setPreviousOptions] = useState({
        ...options,
        location: {
            ...options.farmSceneOptions.location
        }
    });

    useEffect(() => {
        if (world) {
            world.updateOptions(options.farmSceneOptions);

            let worldNeedsToRebuild = options.farmSceneOptions.quality !== previousOptions.farmSceneOptions.quality
                || options.farmSceneOptions.location.lat.toString() !== previousOptions.location.lat.toString()
                || options.farmSceneOptions.location.lng.toString() !== previousOptions.location.lng.toString()
                || options.farmSceneOptions.location.zoom !== previousOptions.location.zoom;

            if (worldNeedsToRebuild) {
                props.setLoadingWorldState(true);
                world.reloadWorld().then(() => props.setLoadingWorldState(false));
            } else {
                props.setLoadingYardState(true);
                world.loadNewFarmyard().then(() => props.setLoadingYardState(false));
            }

            setPreviousOptions({
                ...options,
                location: {
                    ...options.farmSceneOptions.location
                }
            });
        }
    }, [options.farmSceneOptions.quality, options.farmSceneOptions.location.zoom, options.farmSceneOptions.location.lat, options.farmSceneOptions.location.lng, options.farmSceneOptions.yardName, options.farmSceneOptions.brand]);

    useEffect(() => {
        options.setVisibleButtons({...options.visibleButtons, vr: world?.xrSupported, fullScreen: document.fullscreenEnabled});
    }, [world?.xrSupported, options.setVisibleButtons]);

    const setCanvasRef = useCallback(async (node) => {
        if (!node) return;
        canvas.current = node;
        setCanvasToFullSize();

        window.addEventListener("resize", setCanvasToFullSize);
        document.addEventListener("fullscreenchange", () => {
            if (document.fullscreenElement) {
                canvas.current.width = window.screen.width;
                canvas.current.height = window.screen.height;
            } else {
                setCanvasToFullSize();
            }
        });

        props.setLoadingWorldState(true);
        const engine = new Engine(canvas.current, true);
        const farmScene = await FarmScene.CreateNewFarmSceneAsync(engine, canvas.current, options.farmSceneOptions);
        props.setLoadingWorldState(false);
        setWorld(farmScene);
    }, []);

    const setCanvasContainerRef = useCallback((node) => {
        if (!node) return;
        canvasContainer.current = node;
        setCanvasToFullSize();
    }, []);

    function setCanvasToFullSize() {
        if (!canvas.current || !canvasContainer.current) return;
        canvas.current.width = 640;
        canvas.current.height = 400;
        canvas.current.height = canvasContainer.current.scrollHeight;
        canvas.current.width = canvasContainer.current.scrollWidth;
    }

    function resetPosition() {
        if (!world) return;
        world.resetPosition();
    }

    // Use a debounce to persist the value to avoid throttling due to flooding navigation
    let debouncePersistYardRotationTimer;
    let degreesOfLastYardRotation = options.farmSceneOptions.heading;
    function setYardRotation(degrees: number) {
        world && world.rotateYardByDegrees(degrees - degreesOfLastYardRotation);
        degreesOfLastYardRotation = degrees;
        clearTimeout(debouncePersistYardRotationTimer);
        debouncePersistYardRotationTimer = setTimeout(() => persistYardRotation(degrees), 250);
    }

    function persistYardRotation(degrees: number) {
        options.farmSceneOptions.heading = degrees;
        saveToHash(options.farmSceneOptions);
    }

    Hub.onViewReset(() => {
        resetPosition();
    });

    return (
        <div ref={setCanvasContainerRef} className={styles.root}>
            <div className={styles.canvasContainer}>
                <canvas ref={setCanvasRef} className={styles.view}></canvas>
                <FloatingButtonsPanel>
                    <FloatingButtonGroup>
                        <ImmersiveModeButton onToggleImmersiveMode={() => world && world.toggleDeviceOrientationInput()} />
                        {options.visibleButtons.vr && <VRButton handleClick={() => world && world.enterXRSession()} />}
                        {options.visibleButtons.fullScreen && <FullScreenButton handleClick={() => canvas.current.requestFullscreen()} />}
                    </FloatingButtonGroup>
                    <FloatingButtonGroup>
                        <RotateYardButton handleChange={(event) => setYardRotation(Number(event.target.value))} />
                        <ResetPositionButton handleClick={resetPosition} />
                    </FloatingButtonGroup>
                </FloatingButtonsPanel>
            </div>
        </div>
    );
}
