import React, { useEffect, useState } from 'react';
import { IFarmyardMetadata } from '../../../loaders/farmyardMetadata';
import { FancySelect } from '../fancySelect/FancySelect';
import cn from 'classnames';

import '../fancySelect/fancy-select.scss';
import './stock-type-select.scss';
import { IBrands, getBrands } from '../../../loaders/catalog';
import { getIcon } from '../../icons/livestock-icon';

interface IStockTypeSelect {
    yards: IFarmyardMetadata[];
    selectedStockType: string;
    setSelectedStockType: (value: React.SetStateAction<string>) => void;
    disabled: boolean;
    isSmallScreen?: boolean;
};

export const StockTypeSelect: React.FC<IStockTypeSelect> = (props: IStockTypeSelect) => {
    const [open, setOpen] = useState(false);
    const [brands, setBrands] = useState<IBrands[]>([]);

    useEffect(() => {
        setBrands(getBrands());
    }, [props.selectedStockType]);


    function onItemSelected(stockType: string) {
        setOpen(false);
        props.setSelectedStockType(stockType);
    }

    return (
        <div className="stock-type">
            <FancySelect disabled={props.disabled} title="Stock&nbsp;type" renderItem={<div>{getIcon(props.selectedStockType)} <span className="stock-name">{brands?.find(b => b.StockType.toUpperCase().startsWith(props.selectedStockType.toUpperCase()))?.StockDisplayName || props.selectedStockType}</span></div>} open={!props.isSmallScreen && open} setOpen={setOpen}>
                <ul className="items">
                    {brands.filter(b => props.yards.some(y => b.StockType.toUpperCase().startsWith(y.stockType.toUpperCase())))
                        .map(b =>
                            <li key={b.StockType} onClick={() => !props.disabled && onItemSelected(b.StockType)} className={cn("item", {"currentlySelected": b.StockType === props.selectedStockType})}>
                                {getIcon(b.StockType)}
                                <span className="stock-name">{b.StockDisplayName}</span>
                            </li>
                    )}
                </ul>
            </FancySelect>
        </div>
    )
}
