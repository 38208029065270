import React from "react";
import styles from './LeadForm.module.scss';
import { ReactComponent as CheckIcon } from '../icons/check.svg';
import cn from 'classnames';

interface ICustomCheckbox {
    id: string;
    value: boolean;
    required?: boolean;
    handleInputChange: (event) => void;
}

export const CustomCheckbox: React.FC<ICustomCheckbox> = props => {
    return <label htmlFor={props.id} className={cn("pure-u-1", "pure-checkbox", styles.checkbox)}>
        <span className={styles.checkboxWrap}>
            <input type="checkbox" id={props.id} onChange={props.handleInputChange} checked={props.value} required={props.required} />
            <span className={styles.checkboxControl}>
                <CheckIcon />
            </span>
        </span> {props.children}
    </label>
}