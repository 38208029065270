import React, { useEffect, useState } from 'react';
import styles from './OnboardingInstructions.module.scss';
import cn from 'classnames';
import { ReactComponent as ClickAndDragIcon } from '../icons/noun_Horizontal Drag_513081.svg';
import { ReactComponent as KeyboardIcon } from '../icons/noun_Keyboard_151502.svg';
import { ReactComponent as SmartphoneIcon } from '../icons/smartphone.svg';

interface IOnboardingInstructionsProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OnboardingInstructions: React.FC<IOnboardingInstructionsProps> = (props) => {
    const [pages, setPages] = useState<JSX.Element[]>([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        // The second test is needed here for iOS devices because as of iOS 13, user agents no longer distinguish between Safari running on iOS vs macOS
        let isIOSDevice = /iPhone|iPad|iPod/i.test(navigator.userAgent) || (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1);
        let isAndroidDevice = /Android/i.test(navigator.userAgent);

        if (isIOSDevice) {
            pages.push(
                <>
                    <ClickAndDragIcon className={cn(styles.icon, styles.animated, styles.thickenedIcon)} />
                    <span>Touch and drag to <em>look around</em></span>
                </>
            );
        } else if (isAndroidDevice) {
            pages.push(
                <>
                    <SmartphoneIcon className={cn(styles.icon, styles.animated)} />
                    <span>Move device to <em>look around</em></span>
                </>
            );
        } else {
            pages.push(
                <>
                    <ClickAndDragIcon className={cn(styles.icon, styles.animated, styles.thickenedIcon)} />
                    <span>Click and drag to <em>look around</em></span>
                    <span>Use the scroll wheel to <em>zoom in and out</em></span>
                </>,
                <>
                    <KeyboardIcon className={styles.icon} />
                    <span>Use the arrow keys to <em>move</em></span>
                    <span>Double click on the ground to <em>jump to that location</em></span>
                </>
            );
        }
    }, [setPages]);

    let nextPageTimeout;
    function nextPage() {
        clearTimeout(nextPageTimeout);
        setPage(page + 1);
    }

    useEffect(() => {
        if (props.open) {
            nextPageTimeout = setTimeout(nextPage, 3000);
        }
    }, [props.open]);

    useEffect(() => {
        if (page >= pages.length) {
            props.setOpen(false);
        } else if (props.open) {
            nextPageTimeout = setTimeout(nextPage, 3000);
        }
    }, [page, props.open, props.setOpen]);

    return (
        <div className={cn(styles.root, { [styles.open]: props.open })} onClick={nextPage}>
            {pages[page]}
        </div>
    )
}
