import React from 'react';
import { ReactComponent as HelicopterIcon } from '../../icons/helicopter.svg';

interface IResetPositionButton {
    handleClick: () => void;
}

export const ResetPositionButton: React.FC<IResetPositionButton> = (props) => {
    return (
        <button onClick={props.handleClick} title="Reset your position">
            <HelicopterIcon />
        </button>
    );
}
