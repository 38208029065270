interface Secrets {
    mapBoxKey: string;
    mapBoxPrivateUsername: string;
    mapBoxPrivateKey: string;
    mapBoxStyledStyleId: string;
    googleMapApiKey: string;
    googleAnalyticsId: string;
    hotjarId: string;
    hotjarSv: string;
}

let config: Secrets = null;

export async function load() {
    if (!config) {
        config = await (await fetch('/secrets.json')).json();
        mapBoxKey = config.mapBoxKey;
        mapBoxPrivateUsername = config.mapBoxPrivateUsername;
        mapBoxPrivateKey = config.mapBoxPrivateKey;
        mapBoxStyledStyleId = config.mapBoxStyledStyleId;
        googleMapApiKey = config.googleMapApiKey;
        googleAnalyticsId = config.googleAnalyticsId;
        hotjarId = parseInt(config.hotjarId);
        hotjarSv = parseInt(config.hotjarSv);
    }
}

export let mapBoxKey: string;
export let mapBoxPrivateUsername: string;
export let mapBoxPrivateKey: string;
export let mapBoxStyledStyleId: string;
export let googleMapApiKey: string;
export let googleAnalyticsId: string;
export let hotjarId: number;
export let hotjarSv: number;