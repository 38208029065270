
export interface ILeadFormData {
    fullName: string;
    email: string;
    phone: string;
    state: string;
    postcode: string;
    businessName: string;
    comments: string;
    discussCustomisation: boolean;
    agreeToTerms: boolean;
    farmyardUrl: string;
    farmyardName: string
}

export async function sendEnquiry(data: ILeadFormData): Promise<Response> {
    return await fetch('api/send-enquiry', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': getCookie("CSRF-TOKEN")
        }
    });
}


function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
