import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from './GoogleMap.module.scss';
import { Options } from '../../../App';
import { isValidLocation } from '../../../geo/geo';
import { ReactComponent as MarkerIcon } from '../../icons/pointer.svg';
import { geoLocate } from '../../../loaders/google';
import { defaultFarmScene } from '../../../persistence';

const coordinatesForCenterOfAustralia = { lat: -25.2744, lng: 133.7751 };
const zoomLevelToEncompassAustralia = 4;

interface IGoogleMapProps {
    open: boolean;
    onCoordinatesSelected: (coordinates: google.maps.LatLng) => void;
}

export const GoogleMap: React.FC<IGoogleMapProps> = (props) => {
    const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);
    const [marker, setMarker] = useState<google.maps.Marker>(null);
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);

    const autocompleteInputRef = useRef<HTMLInputElement | null>(null);

    // Allow this component to function without a valid context, so url-generator and vr-control pages can use this component
    const options = useContext(Options) || {
        farmSceneOptions: defaultFarmScene,
        setChangeLocationHidden: null
    };

    function getLocation() {
        return isValidLocation(options.farmSceneOptions.location) ? { ...options.farmSceneOptions.location } : coordinatesForCenterOfAustralia;
    }

    function getZoom() {
        return isValidLocation(options.farmSceneOptions.location) ? options.farmSceneOptions.location.zoom : zoomLevelToEncompassAustralia;
    }

    const mapContainer = useCallback(async (node) => {
        if (!node) return;

        const map = new google.maps.Map(node, {
            center: getLocation(),
            zoom: getZoom(),
            mapTypeId: "satellite",
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false
        });

        const marker = new google.maps.Marker({
            position: map.getCenter(),
            map: map,
            draggable: true,
            icon: "/www/images/mapmarker.png"
        });

        setGoogleMap(map);
        setMarker(marker);
    }, []);

    const autocompleteContainer = useCallback(async (node) => {
        if (!node) return;
        autocompleteInputRef.current = node;

        const autocomplete = new google.maps.places.Autocomplete(node, {
            componentRestrictions: { country: "au" },
            fields: ["geometry"]
        });

        setAutocomplete(autocomplete);
    }, []);

    useEffect(() => {
        if (!googleMap || !marker || !autocomplete) return;
        google.maps.event.addListener(googleMap, "click", (event: google.maps.MapMouseEvent) => changeLocationAndAddress(event.latLng));
        google.maps.event.addListener(marker, "dragend", (event: google.maps.MapMouseEvent) => changeLocationAndAddress(event.latLng));
        autocomplete.addListener("place_changed", () => changeLocation(autocomplete.getPlace()?.geometry?.location));
        autocomplete.bindTo("bounds", googleMap);
    }, [googleMap, marker, autocomplete]);

    useEffect(() => {
        if (!googleMap || !marker) return;
        const coordinates = new google.maps.LatLng(getLocation());
        marker.setPosition(coordinates);
        googleMap.setCenter(coordinates);
        googleMap.setZoom(getZoom());
        if (isValidLocation(options.farmSceneOptions.location)) {
            geoLocate(`${coordinates.lat()},${coordinates.lng()}`)
                .then((result) => autocompleteInputRef.current.value = result.address);
        }
        else {
            autocompleteInputRef.current.value = "";
        }
    }, [props.open, options.farmSceneOptions.location.lat, options.farmSceneOptions.location.lng]);

    function changeLocation(location: google.maps.LatLng) {
        props.onCoordinatesSelected(location);
        marker.setPosition(location);
        googleMap.panTo(location);
    }

    function changeLocationAndAddress(location: google.maps.LatLng) {
        changeLocation(location);
        geoLocate(`${location.lat()},${location.lng()}`)
            .then((result) => autocompleteInputRef.current.value = result.address);
    }

    function addZoom(amount: number) {
        googleMap && googleMap.setZoom(googleMap.getZoom() + amount);
    }

    return (
        <div className={styles.root}>
            <div ref={mapContainer} className={styles.map} />
            <label className={styles.addressInput}>
                <MarkerIcon />
                <input type="text" ref={autocompleteContainer} onFocus={(e) => e.target.select()}/>
            </label>
            <div className={styles.mapControls}>
                <button onClick={() => addZoom(1)} title={"Zoom in"}>&#43;</button>
                <button onClick={() => addZoom(-1)} title={"Zoom out"}>&#8722;</button>
            </div>
        </div>
    )
}
