import React from 'react';
import styles from './FloatingButtonsPanel.module.scss';

export const FloatingButtonGroup: React.FC = (props) => {
    return (
        <div className={styles.buttonGroup}>
            {props.children}
        </div>
    );
}

export const FloatingButtonsPanel: React.FC = (props) => {
    return (
        <div className={styles.root}>
            {props.children}
        </div>
    );
}
