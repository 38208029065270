import * as SECRETS from "../loaders/config";
import { ITile, tileCenter2coord } from "../geo/geo";

export function elevationDataUrl(tile: ITile) {
    let { x, y, zoom } = tile;
    let key = SECRETS.mapBoxKey;
    return `https://api.mapbox.com/v4/mapbox.terrain-rgb/${zoom}/${x}/${y}.pngraw?access_token=${key}`
}

export function imageryUrl(tile: ITile) {
    let key = SECRETS.mapBoxKey;
    let format = "jpg"; // png or
    let twoX = "@2x";

    let { x, y, zoom } = tile;

    return `https://api.mapbox.com/v4/mapbox.satellite/${zoom}/${x}/${y}${twoX}.${format}?access_token=${key}`;
}

export function terrainMapUrl(tile: ITile) {
    let username = SECRETS.mapBoxPrivateUsername;
    let styleId = SECRETS.mapBoxStyledStyleId;
    let key = SECRETS.mapBoxPrivateKey;
    let twoX = "@2x";

    let { x, y, zoom } = tile;

    return `https://api.mapbox.com/styles/v1/${username}/${styleId}/tiles/512/${zoom}/${x}/${y}${twoX}?access_token=${key}`;
}

export function staticImageUrl(tile: ITile) {
    let bearing = 0;
    let pitch = 0;

    let tileLatLng = tileCenter2coord(tile);

    let format = "jpg"; // png or jpg
    let twoX = "@2x";
    let key = SECRETS.mapBoxPrivateKey;
    let username = SECRETS.mapBoxPrivateUsername;
    let styleid = SECRETS.mapBoxStyledStyleId;

    return `https://api.mapbox.com/v1/${username}/${styleid}/static/${tileLatLng.lng},${tileLatLng.lat},${tile.zoom},${bearing}/${512}x${512}${twoX}?access_token=${key}`;
}