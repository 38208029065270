import React, { useEffect, useState } from 'react';
import { FancySelect } from '../fancySelect/FancySelect';
import { IFarmyardMetadata } from '../../../loaders/farmyardMetadata';
import cn from 'classnames';

import '../fancySelect/fancy-select.scss';
import styles from './YardSelect.module.scss';

interface IYardSelect {
    yards: IFarmyardMetadata[];
    selectedYard: IFarmyardMetadata;
    setSelectedYard: (value: React.SetStateAction<IFarmyardMetadata>) => void;
    disabled: boolean;
    toggleFullScreenFilters?: () => void;
    isSmallScreen?: boolean;
}

export const YardSelect: React.FC<IYardSelect> = (props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!props.yards.includes(props.selectedYard) || props.selectedYard.capacity < props.yards[0].capacity) {
            props.setSelectedYard(props.yards[0]);
        }
    }, [props.yards, props.setSelectedYard]);

    const yardSize = (yard: IFarmyardMetadata) => yard.size
        ? <span className="yard-size">{yard.size}m<sup>2</sup></span>
        : null;

    function onYardSelected(yard: IFarmyardMetadata) {
        setOpen(false);
        props.setSelectedYard(yard);
    }

    return (
        <div className={cn(styles.root, {[styles.open]: open})}>
            <FancySelect disabled={props.disabled} title="Farm yard" renderItem={<><span className={styles.yardName}>{props.selectedYard.name}</span> {yardSize(props.selectedYard)}</>} open={!props.isSmallScreen && open} setOpen={setOpen}>
                <ul className="items">
                    {props.yards.map((yard) =>
                        <li key={yard.name} onClick={() => !props.disabled && onYardSelected(yard)} className={cn("item", {"currentlySelected": yard === props.selectedYard})}>{yard.name} {yardSize(yard)}</li>
                    )}
                </ul>
            </FancySelect>
            <div className={styles.showFilters} onClick={props.toggleFullScreenFilters}></div>
        </div>
    )
}
