export interface IFarmyardMetadata {
    name: string
    filename: string
    size: number
    stockType: string
    capacity: number
    brand: string
}


let savedFarms: IFarmyardMetadata[];
export async function getAvailableYards(): Promise<IFarmyardMetadata[]> {
    if (savedFarms)
        return savedFarms;

    let farmyards = await loadYardIndex(`farmyards/index.json`);

    savedFarms = sortYardsByCapacity(farmyards);

    return savedFarms;
}

let customFarms: IFarmyardMetadata[];
export async function getCustomYards(): Promise<IFarmyardMetadata[]> {
    if (customFarms)
        return customFarms;

    customFarms = await loadYardIndex(`farmyards/index-custom.json`);
    return customFarms;
}

export function resetCustomYards() {
    customFarms = null;
}

export async function getAllYards(): Promise<IFarmyardMetadata[]> {
    return [... await getAvailableYards(), ... await getCustomYards()];
}

async function loadYardIndex(url: string) {
    let response = await fetch(url);
    return (await response.json()) as IFarmyardMetadata[];
}

export function sortYardsByCapacity(yards: IFarmyardMetadata[]) {
    return yards.sort((a, b) => {
        if (!a.capacity && !b.capacity) return a.name.localeCompare(b.name);
        else if (!a.capacity) return 1;
        else if (!b.capacity) return -1;
        else return a.capacity - b.capacity || a.name.localeCompare(b.name);
    });
}
