import * as signalR from "@microsoft/signalr";



const connection = new signalR.HubConnectionBuilder()
    .withUrl("/hub")
    .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: context => {
            if (context.elapsedMilliseconds < 30000) {
                return 2000; // every 2 seconds for the first 30 seconds
            }
            return 10000; // then every 10 seconds forever
        }
    })
    .build();

connection.onclose(() => statusChanged(Status.disconnected));
connection.onreconnecting(() => statusChanged(Status.disconnected));
connection.onreconnected(() => statusChanged(Status.connected));

export async function connect() {
    try {
        await connection.start().catch(err => {
            console.log(err);
            setTimeout(connect, 5000);
        });

        statusChanged(Status.connected);
    }
    catch (e) {
        console.log(e);
    }
}

export enum Status {
    connected,
    disconnected
}

let notifyStatusChanged: (status: Status) => void;
export function onStatusChange(fn: (status: Status) => void) {
    notifyStatusChanged = fn;
}

function statusChanged(status: Status) {
    notifyStatusChanged && notifyStatusChanged(status);
}

export function onSessionUpdated(fn: (lat: number, lng: number, yard: string, brand: string) => void) {
    connection.on("sessionUpdated", fn);
}

export function updateSession(lat: number, lng: number, yard: string, brand: string) {
    connection.send("updateSession", lat, lng, yard, brand);
}

export function pingClients(id: number) {
    connection.send("pingClients", id);
}

export function onPing(fn: (id: number) => void) {
    connection.on("ping", (id) => fn(id));
}

export function sendPong(id: number) {
    connection.send("pong", id);
}

export function onClientPong(fn: (id: number) => void) {
    connection.on("clientPong", (id) => fn(id));
}

export function resetView() {
    connection.send("resetView");
}

export function onViewReset(fn: () => void) {
    connection.on("viewReset", fn);
}