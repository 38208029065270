import { Animation, Color3, MeshBuilder, Sound, StandardMaterial, Vector3, WebXRCamera, WebXRDefaultExperience, Mesh } from "babylonjs";
import { FarmScene } from "./farmScene";

export interface ICustomXRExperienceConfig {
    viewDistance: number;
    thumbstickThreshold: number;
    stepDistance: number;
    continuousMotionSpeed: number;
    ambientSoundVolume: number;
}

export class CustomXRExperience {
    private farmScene: FarmScene;
    private xr: WebXRDefaultExperience;
    private camera: WebXRCamera;
    private ambientFarmSound: Sound;
    private hasTakenStep = false;
    private lastKnownRealWorldHeight = 0;

    public static async createCustomXRExperienceAsync(farmScene: FarmScene, config: ICustomXRExperienceConfig) {
        let customXR = new CustomXRExperience();
        customXR.farmScene = farmScene;

        customXR.xr = await customXR.farmScene.scene.createDefaultXRExperienceAsync({
            disableTeleportation: true,
            disableDefaultUI: true
        });

        // Exit early if the browser doesn't support XR
        if (!customXR.xr.baseExperience) return null;

        customXR.registerLocomotion(config);
        customXR.registerAmbientSound(config);

        customXR.camera = customXR.xr.baseExperience.camera;
        customXR.camera.minZ = 0.01;
        customXR.camera.maxZ = config.viewDistance;

        customXR.xr.baseExperience.sessionManager.onXRFrameObservable.add(() => customXR.cacheLastKnownWorldHeight());

        return customXR;
    }

    public async enterXRAsync() {
        await this.xr.baseExperience.enterXRAsync("immersive-vr", "local-floor");
    }

    public async resetCamera(position: Vector3, rotation: Vector3) {
        if (this.farmScene.scene.activeCamera === this.camera) {
            let planeMaterial = new StandardMaterial("FadeMaterial", this.farmScene.scene);
            planeMaterial.diffuseColor = Color3.Black();
            planeMaterial.specularColor = Color3.Black();

            var plane = MeshBuilder.CreatePlane("Fade", { width: 1, height: 1 }, this.farmScene.scene);
            plane.material = planeMaterial;

            // Scale plane to camera frustum
            var aspectRatio = this.farmScene.scene.getEngine().getAspectRatio(this.camera);
            var cameraDistance = this.camera.position.length();
            var y = 2 * cameraDistance * Math.tan(this.camera.fov / 2);
            var x = y * aspectRatio;
            plane.scaling = new Vector3(x, y, 1);

            // Position plane relative to camera
            let offset = new Vector3(0, 0, this.camera.minZ + 0.001);
            offset = offset.rotateByQuaternionToRef(this.camera.rotationQuaternion, offset);
            plane.position = this.camera.position.add(offset);
            plane.rotationQuaternion = this.camera.rotationQuaternion.clone();
            plane.setParent(this.camera);

            await this.fadeAnimation(plane, 0, 1, 0.5);
            this.camera.position.x = position.x;
            this.camera.position.z = position.z;
            this.camera.position.y = this.farmScene.level.queryHeight(position.x, position.z) + this.lastKnownRealWorldHeight;
            this.camera.rotation.copyFrom(rotation);
            await this.fadeAnimation(plane, 1, 0, 0.5);

            plane.dispose(false, true);
        }
    }

    private fadeAnimation(target: Mesh, from: number, to: number, seconds: number): Promise<void> {
        return new Promise<void>((resolve, _) => {
            Animation.CreateAndStartAnimation("Fade", target, 'visibility', 60, 60 * seconds, from, to, Animation.ANIMATIONLOOPMODE_CONSTANT, null, () => {
                resolve();
            });
        });
    }

    private cacheLastKnownWorldHeight() {
        this.lastKnownRealWorldHeight = this.camera.realWorldHeight;
    }

    private registerAmbientSound(config: ICustomXRExperienceConfig) {
        let tileSize = this.farmScene.level.center.size;

        this.xr.baseExperience.sessionManager.onXRSessionInit.add(() => {
            let ambientSoundPath = "Audio/felix_blume_ambience_countryside_late_afternoon_cattle_birds_nebraska_usa.mp3";
            this.ambientFarmSound = new Sound("ambience", ambientSoundPath, this.farmScene.scene, null, {
                loop: true,
                volume: config.ambientSoundVolume,
                autoplay: true,
                spatialSound: true,
                maxDistance: tileSize * 3
            });

            let soundEmitterPosition = new Vector3(tileSize * 1.5, 0, tileSize * 1.5);
            soundEmitterPosition.y = this.farmScene.level.queryHeight(soundEmitterPosition.x, soundEmitterPosition.z);
            this.ambientFarmSound.setPosition(soundEmitterPosition);
        });

        this.xr.baseExperience.sessionManager.onXRSessionEnded.add(() => {
            this.ambientFarmSound.dispose();
        });
    }

    private registerLocomotion(config: ICustomXRExperienceConfig) {
        this.xr.input.onControllerAddedObservable.add((controller) => {
            controller.onMotionControllerInitObservable.add((motionController) => {
                const thumbstick = motionController.getComponent("xr-standard-thumbstick");
                if (motionController.handness === "left") {
                    thumbstick.onAxisValueChangedObservable.add((eventData) => {
                        const inputVector = new Vector3(eventData.x, 0, -eventData.y);
                        const inputMagnitude = inputVector.length();

                        if (inputMagnitude >= 0) {
                            const motionDelta = inputVector.normalize().scale(config.continuousMotionSpeed);
                            this.moveCameraLocomotion(motionDelta);
                        }
                    });
                } else {
                    thumbstick.onAxisValueChangedObservable.add((eventData) => {
                        const inputVector = new Vector3(eventData.x, 0, -eventData.y);
                        const inputMagnitude = inputVector.length();

                        if (!this.hasTakenStep && inputMagnitude >= 1 - config.thumbstickThreshold) {
                            const motionDelta = inputVector.normalize().scale(config.stepDistance);
                            this.moveCameraLocomotion(motionDelta);
                            this.hasTakenStep = true;
                        }

                        if (this.hasTakenStep && inputMagnitude <= config.thumbstickThreshold) {
                            this.hasTakenStep = false;
                        }
                    });
                }
            });
        });
    }

    private moveCameraLocomotion(motionDelta: Vector3) {
        motionDelta.rotateByQuaternionToRef(this.camera.rotationQuaternion, motionDelta);
        this.camera.position.addInPlace(motionDelta);
        this.camera.position.y = this.farmScene.level.queryHeight(this.camera.position.x, this.camera.position.z) + this.camera.realWorldHeight;
    }
}