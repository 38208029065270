import { Texture, GroundBuilder, GroundMesh, VertexData, Color3, Mesh, ISize } from "babylonjs";
import { Scene } from "babylonjs/scene";
import { ITileDefinition } from "./tile";

export function buildGround(elevationTexture: Texture, tileDef: ITileDefinition, scene: Scene) {
    let { zoom, x, y } = tileDef.tile;
    let sizeMetres = tileDef.size;

    let numSubdivisions = 256 / Math.pow(2, zoom - 15);
    numSubdivisions = Math.min(numSubdivisions, 256);
    numSubdivisions = 32;
    
    let textureSize = elevationTexture.getSize().width;
    let heights = readHeightmapFromMapboxTexture(elevationTexture, textureSize);
    //heights = waveyTestHeightMap(textureSize);

    let meshName = `Ground_${zoom}_${x}_${y}`;
    let ground = Mesh.CreateGround(meshName, sizeMetres, sizeMetres, numSubdivisions, scene, true) as GroundMesh;

    //ground.renderOverlay = true;
    //ground.overlayColor = new Color3(tileDef.tileOffset.x / 2 + 0.5, tileDef.tileOffset.y / 2 + 0.5, 0);
    //ground.overlayAlpha = 0.2;

    applyHeightMap(ground, heights, numSubdivisions, textureSize)
    ground.cullingStrategy = Mesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;

    return ground;
}

function waveyTestHeightMap(size: number): number[] {
    let heights = new Array<number>(size * size);
    for (let i = 0; i < heights.length; i++) {
        {

            let myX = i / size / size;
            let myZ = i % size / size;
            heights[i] = 1 +
                10 * Math.sin(myX * Math.PI * 2) +
                10 * Math.cos(myZ * Math.PI * 2);
        }
    }
    return heights;
}

function readHeightmapFromMapboxTexture(elevationTexture: Texture, textureSize: number): number[] {
    let buffer = elevationTexture.readPixels() as Uint8Array;

    let heights = new Array<number>(textureSize * textureSize);
    for (let i = 0; i < heights.length; i++) {
        let r = buffer[i * 4];
        let g = buffer[i * 4 + 1];
        let b = buffer[i * 4 + 2];
        let a = buffer[i * 4 + 3];

        // mapbox elevation formula
        // see https://docs.mapbox.com/help/troubleshooting/access-elevation-data/
        let height = -10000 + ((r * 256 * 256 + g * 256 + b) * 0.1);
        heights[i] = height;
    }
    return heights;
}

function applyHeightMap(ground: GroundMesh, heights: number[], numSubdivisions: number, heightMapSize: number) {
    ground.updateMeshPositions(positions => {
        for (var i = 0; i <= numSubdivisions; i++) {
            for (var j = 0; j <= numSubdivisions; j++) {
                let xNorm = j / numSubdivisions;
                let yNorm = i / numSubdivisions;

                let heightMapX = xNorm * (heightMapSize - 1) | 0;
                let heightMapY = yNorm * (heightMapSize - 1) | 0;

                let posIndex = j + i * (numSubdivisions + 1);
                let ix = posIndex * 3 + 1;
                let heightMapIndex = heightMapX + heightMapY * heightMapSize;

                positions[ix] = heights[heightMapIndex];
            }
        }
    }, true)
    ground.refreshBoundingInfo();
}