import React from 'react';
import { ReactComponent as FullscreenIcon } from '../../icons/fullscreen.svg';

interface IFullScreenButton {
    handleClick: () => void;
}

export const FullScreenButton: React.FC<IFullScreenButton> = (props) => {
    return (
        <button onClick={props.handleClick}>
            <FullscreenIcon />
        </button>
    );
}
