import React from 'react';
import { Chevron } from '../../icons/chevron';
import useOnclickOutside from 'react-cool-onclickoutside';

import './fancy-select.scss';

interface IFancySelect {
    title: string;
    renderItem: JSX.Element;
    open: boolean;
    setOpen: (value: boolean) => void;
    disabled: boolean;
}

export const FancySelect: React.FC<IFancySelect> = (props) => {
    const ref = useOnclickOutside(() => {
        props.setOpen(false);
    }, {
        disabled: !props.open
    });

    function close() {
        props.setOpen(false);
    }

    function toggle() {
        props.setOpen(!props.open);
    }

    return (
        <div className={"select-container " + (props.open ? "open" : "")}>
            <div ref={ref} className="select">
                <div onClick={() => !props.disabled && toggle()}>
                    <div className="name">{props.title}</div>
                    <div className="selected">{props.renderItem}
                        <Chevron />
                    </div>
                </div>
                {props.children}
            </div>
        </div>
    );
}
