import { TransformNode, Scene, BoundingInfo, Vector3, MeshBuilder, AbstractMesh, Ray } from "babylonjs";
import { RenderedWorld } from "./meshes/RenderedWorld";
import { ITileDefinition, joinOuterTileEdgesToCenter } from "./meshes/tile";
import { ILocation, displacement } from "./geo/geo";

export function flattenGroundAroundNode(root: TransformNode, world: RenderedWorld) {
    let disposableMeshName = "DisposableMesh";

    let yardOrigin = new AbstractMesh(disposableMeshName, root.getScene());
    yardOrigin.rotationQuaternion = root.rotationQuaternion.clone();
    yardOrigin.position = root.position.clone();
    yardOrigin.setBoundingInfo(totalWorldBoundingInfo(root));
    yardOrigin.computeWorldMatrix();

    let bounds = yardOrigin.getBoundingInfo();

    let minimumPickableYardAreaSize = world.center.size / world.center.groundMesh.subdivisions / 2;
    bounds.boundingBox.extendSize.x = Math.max(minimumPickableYardAreaSize, bounds.boundingBox.extendSize.x);
    bounds.boundingBox.extendSize.z = Math.max(minimumPickableYardAreaSize, bounds.boundingBox.extendSize.z);

    let pickableYardArea = MeshBuilder.CreateBox(disposableMeshName, {
        width: bounds.boundingBox.extendSize.x * 2,
        height: bounds.boundingBox.extendSize.y * 2,
        depth: bounds.boundingBox.extendSize.z * 2
    }, root.getScene());

    pickableYardArea.rotationQuaternion = yardOrigin.rotationQuaternion;
    pickableYardArea.isVisible = false;
    pickableYardArea.isPickable = true;
    pickableYardArea.position = bounds.boundingBox.centerWorld;
    pickableYardArea.computeWorldMatrix();
    pickableYardArea.refreshBoundingInfo();

    let pickableYardAreaBoundingBox = pickableYardArea.getBoundingInfo().boundingBox;
    let extendedSlopedYardAreaExtraSize = world.center.size / world.center.groundMesh.subdivisions * 3;
    let extendedSlopedYardArea = MeshBuilder.CreateBox(disposableMeshName, {
        width: pickableYardAreaBoundingBox.extendSize.x + extendedSlopedYardAreaExtraSize,
        height: bounds.boundingBox.extendSize.y * 2,
        depth: pickableYardAreaBoundingBox.extendSize.z + extendedSlopedYardAreaExtraSize
    }, root.getScene());

    extendedSlopedYardArea.rotationQuaternion = yardOrigin.rotationQuaternion;
    extendedSlopedYardArea.isVisible = false;
    extendedSlopedYardArea.isPickable = true;
    extendedSlopedYardArea.position = bounds.boundingBox.centerWorld;
    extendedSlopedYardArea.computeWorldMatrix();
    extendedSlopedYardArea.refreshBoundingInfo();

    world.allTiles.map((tile) => {
        let ground = tile.groundMesh;
        ground.unfreezeWorldMatrix();
        let numSubdivisions = ground.subdivisions;
        ground.updateMeshPositions(positions => {
            for (let i = 0; i <= numSubdivisions; i++) {
                for (let j = 0; j <= numSubdivisions; j++) {
                    let posIndex = j + i * (numSubdivisions + 1);
                    let ix = posIndex * 3 + 0;
                    let iy = posIndex * 3 + 1;
                    let iz = posIndex * 3 + 2;

                    let point = new Vector3(positions[ix], positions[iy], positions[iz]).add(ground.position);
                    let pickableYardRay = new Ray(point.add(new Vector3(0, -100, 0)), Vector3.Up(), 1000);
                    let pickableYardHit = root.getScene().pickWithRay(pickableYardRay, m => m === pickableYardArea);

                    if (pickableYardHit.pickedPoint) {
                        for (let ii = -1; ii <= 1; ii++) {
                            for (let jj = -1; jj <= 1; jj++) {
                                let adjacentPosIndex = posIndex + ii * (numSubdivisions + 1) + jj;
                                let iix = adjacentPosIndex * 3 + 0;
                                let iiy = adjacentPosIndex * 3 + 1;
                                let iiz = adjacentPosIndex * 3 + 2;

                                if (iix < 0 || iiz >= positions.length) continue;

                                let adjacentPoint = new Vector3(positions[iix], positions[iiy], positions[iiz]).add(ground.position);
                                let extendedSlopedYardAreaRay = new Ray(adjacentPoint.add(new Vector3(0, -100, 0)), Vector3.Up(), 1000);
                                let extendedSlopedYardAreaHit = root.getScene().pickWithRay(extendedSlopedYardAreaRay, m => m === extendedSlopedYardArea);

                                if (extendedSlopedYardAreaHit.pickedPoint) {
                                    positions[iiy] = extendedSlopedYardAreaHit.pickedPoint.y;
                                }
                            }
                        }
                    }
                }
            }
        }, true);

        ground.refreshBoundingInfo();
        ground.updateCoordinateHeights();
        ground.freezeWorldMatrix();
    });

    joinOuterTileEdgesToCenter(world.allTiles);

    root.getScene().getNodes().forEach((n) => {
        if (n.name === disposableMeshName) n.dispose();
    });
}

export function slopeWithGround(root: TransformNode, world: RenderedWorld) {
    let bounds = totalWorldBoundingInfo(root);
    let yardBox = bounds.boundingBox;

    let children = root.getChildMeshes(false);
    let min = children[0].absolutePosition.clone();
    let max = children[0].absolutePosition.clone();
    children.forEach(n => {
        let p = n.absolutePosition;
        min.x = Math.min(p.x, min.x);
        min.z = Math.min(p.z, min.z);
        max.x = Math.max(p.x, max.x);
        max.z = Math.max(p.z, max.z);
    });

    let positions = [yardBox.centerWorld, min, max, new Vector3(min.x, 0, max.z), new Vector3(max.x, 0, min.z)];
    let worldPositions = positions.map((pos, i) => {
        pos.y = world.queryHeight(pos.x, pos.z);
        return pos;
    });


    let [mid, sw, ne, nw, se] = worldPositions;
    let corners = [sw, ne, nw, se];
    corners.sort((a, b) => b.y - a.y);
    let maxCorner = corners[0];
    //drawBox("max", maxCorner);

    root.position.y = maxCorner.y + 0.05;

    let permutations = [{
        a: corners[1],
        b: corners[2]
    }, {
        a: corners[1],
        b: corners[3]
    }, {
        a: corners[2],
        b: corners[3]
    }];

    let results = permutations.map(({ a, b }) => {
        let vectorA = a.subtract(maxCorner);
        let vectorB = b.subtract(maxCorner);
        let normal = vectorA.cross(vectorB).normalize();
        if (normal.y < 0) normal = normal.scale(-1);
        let axis = Vector3.Cross(Vector3.Up(), normal)
        let angle = Math.acos(Vector3.Dot(Vector3.Up(), normal));
        return { axis, angle }
    });
    results.sort((a, b) => Math.abs(b.angle) - Math.abs(a.angle));
    root.rotateAround(maxCorner, results[0].axis, results[0].angle);
}

export function totalWorldBoundingInfo(transformNode: TransformNode) {
    let meshes = transformNode.getChildMeshes(false);
    
    let rootAsMesh = (transformNode as AbstractMesh);
    if (rootAsMesh.getBoundingInfo) {
        meshes.push(rootAsMesh)
    }

    meshes.forEach(m => {
        m.computeWorldMatrix()
        m.refreshBoundingInfo();
    })
    
    var boundingInfo = meshes[0].getBoundingInfo();
    var min = boundingInfo.boundingBox.minimumWorld;
    var max = boundingInfo.boundingBox.maximumWorld;

    for (var i = 1; i < meshes.length; i++) {
        boundingInfo = meshes[i].getBoundingInfo();
        min = Vector3.Minimize(min, boundingInfo.boundingBox.minimumWorld);
        max = Vector3.Maximize(max, boundingInfo.boundingBox.maximumWorld);
    }
    return new BoundingInfo(min, max);
}


export function totalBoundingInfo(transformNode: TransformNode): BoundingInfo {
    let meshes = transformNode.getChildMeshes(false);

    meshes[0].refreshBoundingInfo();
    var boundingInfo = meshes[0].getBoundingInfo();
    var min = boundingInfo.minimum.add(meshes[0].position);
    var max = boundingInfo.maximum.add(meshes[0].position);
    for (var i = 1; i < meshes.length; i++) {
        meshes[i].refreshBoundingInfo();
        boundingInfo = meshes[i].getBoundingInfo();
        min = Vector3.Minimize(min, boundingInfo.minimum.add(meshes[i].position));
        max = Vector3.Maximize(max, boundingInfo.maximum.add(meshes[i].position));
    }
    return new BoundingInfo(min, max);
}

export function renderDebugCubesOnTile(scene: Scene, originOffset: Vector3, tile: ITileDefinition) {
    let parentBox = new TransformNode("Tile helper", scene);
    parentBox.position = originOffset.scale(-1);
    for (var i = 0; i <= 1.001; i += 0.02)
        for (var j = 0; j <= 1.001; j += 0.02) {
            let marker = MeshBuilder.CreateBox(`Box_${i}_${j}`, { size: 0.4 }, scene);
            marker.position.x = (i - 0.5) * tile.size + tile.offsetFromCenterTile.x
            marker.position.z = (j - 0.5) * tile.size + tile.offsetFromCenterTile.x
            marker.position.y = 0;
            marker.parent = parentBox;
        }
}

export function renderSinglePoint(scene: Scene, originLocation: ILocation, location: ILocation, originOffset: Vector3, zoom: number) {

    let marker = MeshBuilder.CreateBox(`SomePoint`, { width: 0.4, depth: 0.5, height: 200 }, scene);

    let disp = displacement(originLocation, location, zoom);
    marker.position = disp;
}