import React, { useEffect, useState } from 'react';
import { FancySelect } from '../fancySelect/FancySelect';
import styles from './BrandSelect.module.scss';
import cn from 'classnames';
import { getBrands } from '../../../loaders/catalog';

interface IBrandSelectProps {
    selectedBrand: string;
    setSelectedBrand: React.Dispatch<React.SetStateAction<string>>;
    selectedStockType: string;
    disabled: boolean;
    isSmallScreen?: boolean;
};

export const BrandSelect: React.FC<IBrandSelectProps> = (props: IBrandSelectProps) => {
    const [open, setOpen] = useState(false);
    const [brands, setBrands] = useState<string[]>([]);
    
    useEffect(() => {
        const brandSet = getBrands();
        setBrands(brandSet.find(b => b.StockType.toUpperCase().startsWith(props.selectedStockType.toUpperCase())).Brands);
    }, [props.selectedStockType]);

    function onBrandSelected(brand: string) {
        setOpen(false);
        props.setSelectedBrand(brand);
    }

    return (
        <div className={styles.root}>
            <FancySelect disabled={props.disabled} title="Brand" renderItem={<span className={styles.yardName}>{props.selectedBrand}</span>} open={!props.isSmallScreen && open} setOpen={setOpen}>
                <ul className="items">
                    {brands.map((brand) =>
                        <li key={brand} onClick={() => !props.disabled && onBrandSelected(brand)} className={cn("item", {"currentlySelected": brand === props.selectedBrand})}>{brand}</li>
                    )}
                </ul>
            </FancySelect>
        </div>
    )
}
