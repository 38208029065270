import React, { useContext, useEffect, useState } from 'react';
import styles from './Toolbar.module.scss';
import cn from 'classnames';
import { ReactComponent as PhoneIcon } from '../icons/phone.svg';
import { ApplyButton } from './applyButton/ApplyButton';
import { getAvailableYards, getCustomYards, IFarmyardMetadata } from '../../loaders/farmyardMetadata';
import { Options } from '../../App';
import { defaultFarmScene, parseCurrentHash, saveAndReload, saveToHash } from '../../persistence';
import { StockTypeSelect } from './stockTypeSelect/StockTypeSelect';
import { WorkingCapacitySlider } from './workingCapacitySlider/WorkingCapacitySlider';
import { YardSelect } from './yardSelect/YardSelect';
import { BrandSelect } from './brandSelect/BrandSelect';
import { ensureProductCatalogLoaded, getBrandOrDefaultForStockType, getBrands } from '../../loaders/catalog';

export interface IToolbar {
    handleEnquireNow: () => void;
    isLoadingYard: boolean;
}

export const Toolbar: React.FC<IToolbar> = props => {
    const options = useContext(Options);

    const [yards, setYards] = useState<IFarmyardMetadata[]>([]);
    const [yardsFilteredByStockType, setYardsFilteredByStockType] = useState<IFarmyardMetadata[]>([]);
    const [yardsFilteredByStockTypeAndCapacity, setYardsFilteredByStockTypeAndCapacity] = useState<IFarmyardMetadata[]>([]);
    const [currentlyViewedYard, setCurrentlyViewedYard] = useState<IFarmyardMetadata | null>(null);
    const [selectedStockType, setSelectedStockType] = useState<string | null>(null);
    const [selectedCapacity, setSelectedCapacity] = useState(0);
    const [selectedYard, setSelectedYard] = useState<IFarmyardMetadata | null>(null);
    const [currentlyViewedBrand, setCurrentlyViewedBrand] = useState<string | null>(null);
    const [selectedBrand, setSelectedBrand] = useState<string | null>(options.farmSceneOptions.brand);
    const [isLargeScreenSize, setIsLargeScreenSize] = useState(false);
    const [isFilterPanelVisible, setIsFilterPanelVisible] = useState(false);

    useEffect(() => {
        ensureProductCatalogLoaded().then(() => {
            getAvailableYards().then((availableYards) => {
                if (!availableYards.find(y => y.name === options.farmSceneOptions.yardName)) {
                    getCustomYards().then((customYards) => {
                        let customYard = customYards.find(y => y.name === options.farmSceneOptions.yardName);
                        if (customYard) {
                            setYards([customYard]);
                        } else {
                            setYards(availableYards);
                        }
                    })
                } else {
                    setYards(availableYards);
                }
            });
        });
    }, [getAvailableYards, setYards]);

    useEffect(() => {
        setYardsFilteredByStockType(yards.filter(yard => !selectedStockType || (!yard.stockType || selectedStockType.toUpperCase().startsWith(yard.stockType.toUpperCase()))));
        setSelectedCapacity(0);

        if (selectedStockType && !getBrands()?.find(b => b.StockType.toUpperCase().startsWith(selectedStockType.toUpperCase())).Brands.includes(selectedBrand)) {
            let defaultBrand = getBrandOrDefaultForStockType(null, selectedStockType);
            setSelectedBrand(defaultBrand);
            setCurrentlyViewedBrand(defaultBrand);
        }
    }, [yards, selectedStockType, setYardsFilteredByStockType, setSelectedCapacity]);

    useEffect(() => {
        const filteredYards = isLargeScreenSize 
            ? yardsFilteredByStockType.filter(yard => !selectedCapacity || (!yard.capacity || yard.capacity >= selectedCapacity))
            : yardsFilteredByStockType;
        setYardsFilteredByStockTypeAndCapacity(filteredYards);

        if (filteredYards.length && currentlyViewedYard === null) {
            let initialYard = filteredYards.find(y => y.name === options.farmSceneOptions.yardName) || filteredYards[0];
            setCurrentlyViewedYard(initialYard);
            setSelectedStockType(initialYard.stockType || "Cattle");
            setSelectedYard(initialYard);
            setSelectedBrand(options.farmSceneOptions.brand);
            setCurrentlyViewedBrand(options.farmSceneOptions.brand);
        }
    }, [yardsFilteredByStockType, selectedCapacity, setYardsFilteredByStockTypeAndCapacity, setCurrentlyViewedYard, setSelectedStockType, setSelectedYard, isLargeScreenSize]);

    function applyFilter() {
        let updatedOptions = parseCurrentHash();
        updatedOptions.yardName = selectedYard.name;
        if (selectedStockType === "Cattle") {
            updatedOptions.brand = getBrandOrDefaultForStockType(selectedBrand, selectedYard.stockType);
        } else {
            updatedOptions.brand = null;
        }
        saveAndReload(updatedOptions);
        setCurrentlyViewedYard(selectedYard);
        setSelectedBrand(updatedOptions.brand);
        setCurrentlyViewedBrand(updatedOptions.brand);
    }

    useEffect(() => {
        if (!props.isLoadingYard) {
            setIsFilterPanelVisible(false);
            options.setChangeLocationHidden(false);
        }
    }, [props.isLoadingYard, setIsFilterPanelVisible, options.setChangeLocationHidden])

    useEffect(() => {
        const mql = window.matchMedia(`(max-width: ${styles.tabletSmall})`);
        if (!mql) {
            setIsLargeScreenSize(false);
            return;
        }
        setIsLargeScreenSize(!mql.matches);

        const onChange = (ev: MediaQueryListEvent) => {
            setIsLargeScreenSize(!ev.matches);
        }

        mql.addEventListener ? mql.addEventListener('change', onChange) : mql.addListener(onChange);

        return function cleanup() {
            if (mql.removeEventListener)
                mql.removeEventListener('change', onChange);
            else
                mql.removeListener(onChange);
        }
    }, [setIsLargeScreenSize])

    function resetFarmSceneToDefault() {
        if (props.isLoadingYard) return;
        saveToHash(defaultFarmScene);
        let defaultYard = yards.find((yard) => yard.name === defaultFarmScene.yardName) || yards[0];
        setSelectedStockType(defaultYard.stockType || "Cattle");
        setCurrentlyViewedYard(defaultYard);
        setSelectedYard(defaultYard);
        setSelectedCapacity(yardsFilteredByStockType.filter(y => y.capacity).map(y => y.capacity).sort((a, b) => a - b)[0]);
        setSelectedBrand(getBrandOrDefaultForStockType(defaultYard.brand, defaultYard.stockType));
        setCurrentlyViewedBrand(getBrandOrDefaultForStockType(defaultYard.brand, defaultYard.stockType));
    }

    function toggleFullScreenFilters() {
        setIsFilterPanelVisible(!isFilterPanelVisible);
        options.setChangeLocationHidden(!isFilterPanelVisible)
    }

    function showApplyButton() {
        return props.isLoadingYard
            || currentlyViewedYard !== selectedYard
            || (selectedStockType === "Cattle" && currentlyViewedBrand !== selectedBrand);
    }

    return (
        <>
            <div className={styles.toolbar}>
                <div className={styles.toolbarContainer}>
                    <div className={styles.toolbarLogo} onClick={resetFarmSceneToDefault}>
                        <img src="www/images/mc-virtual-yard-experience.png" />
                    </div>
                    <h1><em>VR</em> YARD VIEWER</h1>
                    {!!currentlyViewedYard && (
                        isLargeScreenSize ? (
                            <>
                                <StockTypeSelect disabled={props.isLoadingYard} yards={yards} selectedStockType={selectedStockType} setSelectedStockType={setSelectedStockType} />
                                <WorkingCapacitySlider disabled={props.isLoadingYard} yards={yardsFilteredByStockType} selectedCapacity={selectedCapacity} setSelectedCapacity={setSelectedCapacity} />
                                <YardSelect disabled={props.isLoadingYard} yards={yardsFilteredByStockTypeAndCapacity} selectedYard={selectedYard} setSelectedYard={setSelectedYard} />
                                {selectedStockType === "Cattle" && <BrandSelect disabled={props.isLoadingYard} selectedStockType={selectedStockType} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} />}
                                <ApplyButton isLoadingYard={props.isLoadingYard} onClick={applyFilter} hidden={!showApplyButton()} />
                            </>
                        ) : (
                                <>
                                    <YardSelect disabled={props.isLoadingYard} yards={yards} selectedYard={selectedYard} setSelectedYard={setSelectedYard} toggleFullScreenFilters={toggleFullScreenFilters} />
                                </>
                            )
                    )}

                    <div className={styles.toolbarSpacer}></div>
                    <div className={cn(styles.toolbarSection, styles.cta, { [styles.applyIsVisible]: currentlyViewedYard !== selectedYard && isLargeScreenSize })}>
                        <div>Ready to <em>make it yours?</em></div>
                        <button onClick={() => props.handleEnquireNow && props.handleEnquireNow()}>ENQUIRE NOW</button>
                    </div>
                    <a href="tel:1300306304" className={styles.phone}>
                        <PhoneIcon />
                    </a>
                    <div className={styles.break}></div>
                </div>
            </div>

            {!!currentlyViewedYard && !isLargeScreenSize &&
                <div className={cn('largeFilterPanel', { 'visible': isFilterPanelVisible })}>
                    <div className={styles.filterPanelContainer}>
                        <div className={styles.scrollRegion}>
                            <StockTypeSelect isSmallScreen disabled={props.isLoadingYard} yards={yards} selectedStockType={selectedStockType} setSelectedStockType={setSelectedStockType} />
                            {selectedStockType === "Cattle" && <BrandSelect isSmallScreen disabled={props.isLoadingYard} selectedStockType={selectedStockType} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} />}
                            <YardSelect isSmallScreen disabled={props.isLoadingYard} yards={yardsFilteredByStockTypeAndCapacity} selectedYard={selectedYard} setSelectedYard={setSelectedYard} />
                        </div>
                        <ApplyButton isLoadingYard={props.isLoadingYard} onClick={applyFilter} hidden={!showApplyButton()} />
                    </div>
                </div>
            }
        </>
    );
}
