import React from 'react';
import styles from './Attribution.module.scss';

export const Attribution: React.FC = () => {
    return (
        <>
            <div className={styles.attribution}>
                Map data © 2020 Google
                | © <a target="_blank" href="https://www.mapbox.com/about/maps/">Mapbox</a>
                | © <a target="_blank" href="http://www.openstreetmap.org/about/">OpenStreetMap</a>
                | <a target="_blank" href="https://www.mapbox.com/map-feedback/#/-74.5/40/10">Improve this map</a>
            </div>
            <a href="http://mapbox.com/about/maps" target="_blank" className={styles.mapboxWordmark}/>
        </>
    );
}
