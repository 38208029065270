import React, { useContext, useState } from 'react';
import { CustomCheckbox } from './CustomCheckbox';
import { sendEnquiry } from './sendEnquiry';
import cn from 'classnames';
import styles from './LeadForm.module.scss';
import { Spinner } from '../spinner/Spinner';
import { Options } from '../../App';


export interface ILeadForm {
    open: boolean;
    setClosed: () => void;
}

function useForm<T>(initialValues: T) {
    const [inputs, setInputs] = useState(initialValues);
    
    const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.id]: event.target.type === "checkbox" ? event.target.checked : event.target.value}));
    }

    return {
      handleInputChange,
      inputs
    };
}


export const LeadForm: React.FC<ILeadForm> = props => {
    const options = useContext(Options);
    
    const {inputs, handleInputChange } = useForm({
        fullName: "",
        email: "",
        phone: "",
        state: "",
        postcode: "",
        businessName: "",
        comments: "",
        discussCustomisation: false,
        agreeToTerms: false,
        farmyardUrl: "",
        farmyardName: ""
    });

    inputs.farmyardUrl = window.location.href;
    inputs.farmyardName = options.farmSceneOptions.yardName;

    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [errors, setErrors] = useState(null);

    const handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
        }
        setSending(true);
        setErrors(null);
        let errorMessages = ["Failed to send enquiry, please try again."];
        try {
            const response = await sendEnquiry(inputs);
            
            if (response.ok) {
                setSent(true);
                return;
            }

            if (response.status == 400) {
                let error = await response.json();
                
                if (!!error) {
                    if (error.hasOwnProperty('errors') && error.hasOwnProperty('title')) {
                        errorMessages = Object.keys(error.errors)
                            .map((k) => error.errors[k] as string[])
                            .reduce<string[]>(
                                (acc, cur) => {
                                    return [...acc, ...cur];
                                },
                                [error['title'] as string]
                            );
                    }
                }
            }
        }
        catch(e) {
            console.error(e);
        }
        finally {
            setSending(false);
        }

        setErrors(errorMessages);
        setSending(false);
    }

    const renderErrors = () => {
        if (!errors) return;

        return <div className={styles.errors}>
            <div>Please correct the following errors:</div>
            <ul>
                {errors.map((e, index) => <li key={index}>{e}</li>)}
            </ul>
        </div>;
    }

    return <div className={cn(styles.root, { [styles.open]: props.open })}>
        <div className={cn(styles.form, {[styles.sent]: sent})}>
            <button className={styles.closeButton} onClick={() => props.setClosed()}>&#x2715;</button>
            
            <div className={cn(styles.sentMessage, styles.title)}>Thanks for your enquiry! We'll get back to you soon.</div>

            <div className={styles.sentMessage}>
                <button className={cn(styles.sendEnquiry, styles.close)} onClick={() => props.setClosed()}>Close</button>
            </div>

            <div className={styles.scrollable}>
                <div className={styles.title}>Ready to <em>make it yours?</em></div>
                <div className={styles.explanation}>Please <em>provide your details</em> and a member of our team will contact you to <em>design a solution</em> that fits your business. And if you know what you need, <em>we'll help you get it.</em></div>

                <form className="pure-form pure-form-stacked" onSubmit={handleSubmit}>
                    <fieldset>
                        <div className="pure-g">
                            <div className="pure-u-1 pure-u-md-11-24">
                                <label htmlFor="fullName">Full Name*</label>
                                <input type="text" id="fullName" className="pure-u-1" onChange={handleInputChange} value={inputs.fullName} required></input>
                            </div>
                            <div className="pure-u-md-1-24"></div>
                            <div className="pure-u-1 pure-u-md-12-24">
                                <label htmlFor="email">Email*</label>
                                <input type="email" id="email" className="pure-u-1" onChange={handleInputChange} value={inputs.email} required></input>
                            </div>

                            <div className="pure-u-1 pure-u-md-11-24">
                                <label htmlFor="phone">Phone*</label>
                                <input type="tel" id="phone" className="pure-u-1" onChange={handleInputChange} value={inputs.phone} required></input>
                            </div>
                            <div className="pure-u-md-1-24"></div>
                            <div className="pure-u-1 pure-u-md-1-2">
                                <label htmlFor="state">State*</label>
                                <div className={styles.stateSelect}>
                                    <select id="state" required onChange={handleInputChange} value={inputs.state}>
                                        <option value="" disabled>Select your state</option>
                                        <option value="ACT">ACT</option>
                                        <option value="NSW">NSW</option>
                                        <option value="NT">NT</option>
                                        <option value="QLD">QLD</option>
                                        <option value="SA">SA</option>
                                        <option value="TAS">TAS</option>
                                        <option value="VIC">VIC</option>
                                        <option value="WA">WA</option>
                                    </select>
                                </div>
                            </div>
                            <div className="pure-u-1 pure-u-md-11-24">
                                <label htmlFor="postcode">Postcode*</label>
                                <input type="text" id="postcode" className="pure-u-1" onChange={handleInputChange} value={inputs.postcode} required></input>
                            </div>

                            <div className="pure-u-1">
                                <label htmlFor="businessName">Business Name</label>
                                <input type="text" id="businessName" className="pure-u-1" onChange={handleInputChange} value={inputs.businessName}></input>
                            </div>
                            <div className="pure-u-1">
                                <label htmlFor="comments">Comments</label>
                                <textarea id="comments" placeholder="e.g. Let us know if you have specific requests in mind." onChange={handleInputChange} value={inputs.comments}></textarea>
                            </div>
                            <CustomCheckbox id="discussCustomisation" handleInputChange={handleInputChange} value={inputs.discussCustomisation}>I would like to discuss customisation options.</CustomCheckbox>
                            
                            <CustomCheckbox id="agreeToTerms" handleInputChange={handleInputChange} value={inputs.agreeToTerms} required={true}>
                                <span>I agree to <a href="https://www.metalcorpsteel.com.au/privacy-policy" target="_blank">Metalcorp Privacy Policy</a>, <a href="https://www.metalcorpsteel.com.au/terms-conditions" target="_blank">Terms &amp; Conditions</a> and <a href="https://www.metalcorpsteel.com.au/resources/conditions-sale" target="_blank">Conditions of Sale</a>.</span>
                            </CustomCheckbox>
                            
                        </div>
                    </fieldset>

                    <button type="submit" className={styles.sendEnquiry} disabled={sending}>{ sending ? <Spinner /> : "SEND ENQUIRY" }</button>
                    
                    {renderErrors()}
                </form>
            </div>
        </div>
    </div>;
};