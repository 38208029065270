import { IVector2 } from "./farmyard.model";

let catalog: ICatalog;
let brands: IBrands[];

export interface ICatalog {
    CatalogItems: ICatalogItem[];
}

export interface ICatalogItem {
    LivestockType: string;
    LivestockDisplayName: string;
    Categories: ICategory[];
}

export interface ICategory {
    Id: number;
    Name: string;
    DisplayText: string;
    IsBrand: boolean;
    Products: IProduct[];
}

export interface IProduct {
    DrawingNumber: string;
    TemplateNames: string[];
    Active: boolean;
    Revisions: ICatalogItemRevision[];
}

export interface ICatalogItemRevision {
    RevisionID: number;
    Title: string;
    Height: number;
    Length: number;
    Width: number;
    Weight: number;
    Description: string;
    AustralianMade: boolean;
    PngImage: string;
    SvgImage: string;
    GlbModel: string;
    GlbModelAdjustments: IGlbModelAdjustment;
    NumberOfPins: number;
    ProductCodesAndNames: IProductCodeAndName[];
    SnapPoints: IVector2[];
}

export interface IGlbModelAdjustment {
    Rotation: number;
    Scale: number;
    Position: IVector2;
}

export interface IProductCodeAndName {
    ProductCode: string;
    ProductName: string;
}

export interface IBrands {
    StockType: string;
    StockDisplayName: string;
    Brands: string[];
}

export function getBrandOrDefaultForStockType(brand: string, stockType: string): string {
    if(!brands) return brand;

    return brands.find(b => b.StockType.toUpperCase() === stockType.toUpperCase())?.Brands.some(b => b.toUpperCase() === brand?.toUpperCase())
        ? brand
        : brands.find(b => b.StockType.toUpperCase() === stockType.toUpperCase())?.Brands[0];
}

export async function ensureProductCatalogLoaded() {
    if(!!catalog)
        return;

    let response = await fetch('catalog/catalog.json');
    catalog = await response.json() as ICatalog;
    brands = catalog.CatalogItems.map(i => <IBrands>{
        StockType: i.LivestockType,
        StockDisplayName: i.LivestockDisplayName,
        Brands: i.Categories.filter(c => c.IsBrand).map(c => c.Name)
    });
}

export function getBrands(): IBrands[]
{
    return brands;
}

export function getProductByDrawingNumber(drawingNumber: string, stockType: string): IProduct {    
    const catalogItem = catalog.CatalogItems.find(c => c.LivestockType.toUpperCase() === stockType.toUpperCase());

    if(!catalogItem) return;

    return unbrandedProductsFlatList(catalogItem).find(p => p.DrawingNumber === drawingNumber);
}

export function getProductByBrandAndTemplate(brand: string, templateName: string, stockType: string)
{
    const catalogItem = catalog.CatalogItems.find(c => c.LivestockType.toUpperCase() === stockType.toUpperCase());

    if(!catalogItem) return;

    const category = catalogItem.Categories.find(c => !!c &&  c.Name.toUpperCase() === brand?.toUpperCase());

    const foundProduct = category?.Products.find(p => p.TemplateNames.some(n => n.toUpperCase() === templateName.toUpperCase()));

    if(!!foundProduct) return foundProduct;

    // Couldn't find it in that category? Could be unbranded
    return unbrandedProductsFlatList(catalogItem).find(p => p.TemplateNames.some(n => n.toUpperCase() === templateName.toUpperCase()));
}

export function getModelPathForProduct(product: IProduct, revisionId: number)
{
    const catalogItem = catalog.CatalogItems.find(i => i.Categories.some(c => c.Products.some(p => p === product)));
    const category = catalogItem?.Categories.find(c => c.Products.some(p => p === product));

    const revision = product?.Revisions.find(r => r.RevisionID === revisionId);

    if(!category || !revision)
        return;
    
    return `${catalogItem.LivestockType.toString()}/${category.Name}/${revision.GlbModel}`;
}

function unbrandedProductsFlatList(catalogItem: ICatalogItem): IProduct[] {
    return catalogItem.Categories.filter(c => !c.IsBrand)
        .map(c => c.Products)
        .filter(p => !!p)
        .reduce((a, b) => a.concat(b));
}