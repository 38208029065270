import { Scene, NodeMaterial, Texture, DynamicTexture } from "babylonjs";
import * as BABYLON from "babylonjs";
import { ITileDefinition } from "../meshes/tile";
import { getOrCreateTexture } from "./materialHelpers";
import { imageryOverlayUrl } from "../loaders";
import Jimp from 'jimp/es';
import { isValidLocation } from "../geo/geo";
import { parseCurrentHash } from "../persistence";

export interface ITerrainMaterialOptions {
    terrainMapTexture: Texture,
    grassTexture: Texture,
    dirtTexture: Texture,
    roadTexture: Texture,
    waterTexture: Texture
}

let urls = {
    "grass": "./Textures/23_dry grass texture-seamless.jpg",
    "dirt": "./Textures/1_dry grass texture-seamless.jpg",
    "road": "./Textures/113_asphalt road texture-seamless.jpg",
    "water": "./Textures/Water_Diffuse.jpg"
}

export async function terrainMaterial(scene: Scene, terrainMapTexture: Texture, tileDef: ITileDefinition) {
    let tileSize = tileDef.size;

    let grassTexture = getOrCreateTexture("GrassTiled", scene, () => {
        let tex = new Texture(urls.grass, scene);
        tex.uScale = tex.vScale = tileSize / 3;
        return tex;
    });
    
    let dirtTexture = getOrCreateTexture("DirtTiled", scene, () => {
        let tex = new Texture(urls.dirt, scene);
        tex.uScale = tex.vScale = tileSize / 3;
        return tex;
    });
    
    let roadTexture = getOrCreateTexture("RoadTiled", scene, () => {
        let tex = new Texture(urls.road, scene);
        tex.uScale = tex.vScale = tileSize / 10;
        return tex;
    });

    let waterTexture = getOrCreateTexture("WaterTiled", scene, () => {
        let tex = new Texture(urls.water, scene);
        tex.uScale = tex.vScale = tileSize / 5;
        return tex;
    });

    let opts = {
        grassTexture, dirtTexture, roadTexture, waterTexture, terrainMapTexture
    }

    return terrainMaterialCopyPaste(scene, tileDef, opts);

}

async function terrainMaterialCopyPaste(scene: Scene, tileDef: ITileDefinition, opts: ITerrainMaterialOptions) {
    const perlinFactor = 25;
    let { zoom, x, y } = tileDef.tile;

    var nodeMaterial = new BABYLON.NodeMaterial(`Terrain_${zoom}_${x}_${y}`);
    var satelliteTexture = new DynamicTexture("Satellite Texture", 1024, scene, false);
    var satelliteTextureContext = satelliteTexture.getContext();

    if (isValidLocation(parseCurrentHash().location)) {
        let img = await Jimp.read(imageryOverlayUrl(tileDef));
        img.blur(25);
        img.crop(128, 128, 256, 256);
        img.resize(1024, 1024);
        let imageData = new ImageData(
            Uint8ClampedArray.from(img.bitmap.data),
            img.bitmap.width,
            img.bitmap.height
        );
        satelliteTextureContext.putImageData(imageData, 0, 0);
        satelliteTexture.update();
    } else {
        let img = new Image();
        img.crossOrigin = "anonymous";
        img.src = imageryOverlayUrl(tileDef);
        img.onload = () => {
            satelliteTextureContext.drawImage(img, 0, 0);
            satelliteTexture.update();
        }
    }

    // InputBlock
    var position = new BABYLON.InputBlock("position");
    position.setAsAttribute("position");

    // TransformBlock
    var WorldPos = new BABYLON.TransformBlock("WorldPos");
    WorldPos.complementZ = 0;
    WorldPos.complementW = 1;

    // InputBlock
    var World = new BABYLON.InputBlock("World");
    World.setAsSystemValue(BABYLON.NodeMaterialSystemValues.World);

    // TransformBlock
    var Worldposition = new BABYLON.TransformBlock("World position");
    Worldposition.complementZ = 0;
    Worldposition.complementW = 1;

    // LightBlock
    var Lights = new BABYLON.LightBlock("Lights");

    // TransformBlock
    var Worldnormal = new BABYLON.TransformBlock("World normal");
    Worldnormal.complementZ = 0;
    Worldnormal.complementW = 0;

    // InputBlock
    var normal = new BABYLON.InputBlock("normal");
    normal.setAsAttribute("normal");

    // InputBlock
    var cameraPosition = new BABYLON.InputBlock("cameraPosition");
    cameraPosition.setAsSystemValue(BABYLON.NodeMaterialSystemValues.CameraPosition);

    // AddBlock
    var Add = new BABYLON.AddBlock("Add");

    // AddBlock
    var Add1 = new BABYLON.AddBlock("Add");

    // LerpBlock
    var Road = new BABYLON.LerpBlock("Road");

    // InputBlock
    var Black = new BABYLON.InputBlock("Black");
    Black.value = new BABYLON.Color3(0, 0, 0);
    Black.isConstant = false;
    Black.visibleInInspector = false;

    // LerpBlock
    var Water = new BABYLON.LerpBlock("Water");

    // TextureBlock
    var WaterTexture = new BABYLON.TextureBlock("WaterTexture");
    WaterTexture.texture = opts.waterTexture;

    // InputBlock
    var uv = new BABYLON.InputBlock("uv");
    uv.setAsAttribute("uv");

    // TextureBlock
    var RoadTexture = new BABYLON.TextureBlock("RoadTexture");
    RoadTexture.texture = opts.roadTexture;

    // TextureBlock
    var TerrainMapTexture = new BABYLON.TextureBlock("TerrainMapTexture");
    TerrainMapTexture.texture = opts.terrainMapTexture;

    // LerpBlock
    var Grass = new BABYLON.LerpBlock("Grass");

    // LerpBlock
    var Lerp = new BABYLON.LerpBlock("Lerp");

    // TextureBlock
    var GrassTexture = new BABYLON.TextureBlock("GrassTexture");
    GrassTexture.texture = opts.grassTexture;

    // TextureBlock
    var DirtTexture = new BABYLON.TextureBlock("DirtTexture");
    DirtTexture.texture = opts.dirtTexture;

    // ClampBlock
    var Clamp = new BABYLON.ClampBlock("Clamp");
    Clamp.minimum = 0;
    Clamp.maximum = 1;

    // RemapBlock
    var Remap = new BABYLON.RemapBlock("Remap");
    Remap.sourceRange = new BABYLON.Vector2(-0.1, 0.5);
    Remap.targetRange = new BABYLON.Vector2(0, 1);

    // SimplexPerlin3DBlock
    var SimplexPerlinD = new BABYLON.SimplexPerlin3DBlock("SimplexPerlin3D");

    // VectorSplitterBlock
    var VectorSplitter = new BABYLON.VectorSplitterBlock("VectorSplitter");

    // MultiplyBlock
    var Multiply = new BABYLON.MultiplyBlock("Multiply");

    // InputBlock
    var PerlinSize = new BABYLON.InputBlock("Perlin Size");
    PerlinSize.value = new BABYLON.Vector2(perlinFactor, perlinFactor);
    PerlinSize.isConstant = false;
    PerlinSize.visibleInInspector = false;

    // FragmentOutputBlock
    var FragmentOutput = new BABYLON.FragmentOutputBlock("FragmentOutput");

    // TransformBlock
    var WorldPosViewProjectionTransform = new BABYLON.TransformBlock("WorldPos * ViewProjectionTransform");
    WorldPosViewProjectionTransform.complementZ = 0;
    WorldPosViewProjectionTransform.complementW = 1;

    // InputBlock
    var ViewProjection = new BABYLON.InputBlock("ViewProjection");
    ViewProjection.setAsSystemValue(BABYLON.NodeMaterialSystemValues.ViewProjection);

    // VertexOutputBlock
    var VertexOutput = new BABYLON.VertexOutputBlock("VertexOutput");

    // FogBlock
    var Fog = new BABYLON.FogBlock("Fog");

    // TextureBlock
    var SatelliteTexture = new BABYLON.TextureBlock("SatelliteTexture");
    SatelliteTexture.texture = satelliteTexture;

    // LerpBlock
    var SatelliteLerp = new BABYLON.LerpBlock("SatelliteLerp");

    // ColorMergerBlock
    var SatelliteColorMerger = new BABYLON.ColorMergerBlock("SatelliteColorMerger");

    // ColorSplitterBlock
    var SatelliteColorSplitter = new BABYLON.ColorSplitterBlock("SatelliteColorSplitter");

    // GradientBlock
    var SatelliteGradient = new BABYLON.GradientBlock("SatelliteGradient");
    SatelliteGradient.colorSteps = [];
    SatelliteGradient.colorSteps.push(new BABYLON.GradientBlockColorStep(0, new BABYLON.Color3(0, 0, 0)));
    SatelliteGradient.colorSteps.push(new BABYLON.GradientBlockColorStep(1, new BABYLON.Color3(1, 1, 1)));

    // ScaleBlock
    var SatelliteBlueScale = new BABYLON.ScaleBlock("SatelliteBlueScale");

    // InputBlock
    var SatelliteBlueScaleFactor = new BABYLON.InputBlock("SatelliteBlueScaleFactor");
    SatelliteBlueScaleFactor.value = 0.3;

    // LerpBlock
    var SatelliteMask = new BABYLON.LerpBlock("SatelliteMask");

    // Connections
    position.output.connectTo(WorldPos.vector);
    World.output.connectTo(WorldPos.transform);
    WorldPos.output.connectTo(WorldPosViewProjectionTransform.vector);
    ViewProjection.output.connectTo(WorldPosViewProjectionTransform.transform);
    WorldPosViewProjectionTransform.output.connectTo(VertexOutput.vector);
    position.output.connectTo(Worldposition.vector);
    World.output.connectTo(Worldposition.transform);
    Worldposition.output.connectTo(Lights.worldPosition);
    normal.output.connectTo(Worldnormal.vector);
    World.output.connectTo(Worldnormal.transform);
    Worldnormal.output.connectTo(Lights.worldNormal);
    cameraPosition.output.connectTo(Lights.cameraPosition);
    Black.output.connectTo(Road.left);
    uv.output.connectTo(RoadTexture.uv);
    RoadTexture.rgb.connectTo(Road.right);
    uv.output.connectTo(TerrainMapTexture.uv);
    TerrainMapTexture.g.connectTo(Road.gradient);
    Road.output.connectTo(Add1.left);
    Black.output.connectTo(Water.left);
    uv.output.connectTo(WaterTexture.uv);
    WaterTexture.rgb.connectTo(Water.right);
    TerrainMapTexture.b.connectTo(Water.gradient);
    Water.output.connectTo(Add1.right);
    Add1.output.connectTo(Add.left);
    Black.output.connectTo(Grass.left);
    uv.output.connectTo(GrassTexture.uv);
    GrassTexture.rgb.connectTo(Lerp.left);
    uv.output.connectTo(DirtTexture.uv);
    DirtTexture.rgb.connectTo(Lerp.right);
    uv.output.connectTo(Multiply.left);
    PerlinSize.output.connectTo(Multiply.right);
    Multiply.output.connectTo(VectorSplitter.xyIn);
    VectorSplitter.xyzOut.connectTo(SimplexPerlinD.seed);
    SimplexPerlinD.output.connectTo(Remap.input);
    Remap.output.connectTo(Clamp.value);
    Clamp.output.connectTo(Lerp.gradient);
    Lerp.output.connectTo(Grass.right);
    TerrainMapTexture.r.connectTo(Grass.gradient);
    TerrainMapTexture.r.connectTo(SatelliteMask.gradient);
    Black.output.connectTo(SatelliteMask.left);
    Grass.output.connectTo(SatelliteLerp.right);
    SatelliteTexture.connectTo(SatelliteColorSplitter);
    SatelliteColorSplitter.g.connectTo(SatelliteColorMerger.g);
    SatelliteColorSplitter.r.connectTo(SatelliteColorMerger.r);
    SatelliteColorSplitter.b.connectTo(SatelliteBlueScale.input);
    SatelliteBlueScaleFactor.output.connectTo(SatelliteBlueScale.factor);
    SatelliteBlueScale.output.connectTo(SatelliteColorMerger.b);
    SatelliteColorSplitter.g.connectTo(SatelliteGradient.gradient);
    SatelliteColorMerger.rgbOut.connectTo(SatelliteLerp.left);
    SatelliteGradient.output.connectTo(SatelliteLerp.gradient);
    SatelliteLerp.output.connectTo(SatelliteMask.right);
    SatelliteMask.output.connectTo(Add.right);
    Add.output.connectTo(Lights.diffuseColor);
    Lights.diffuseOutput.connectTo(Fog.input);
    WorldPos.connectTo(Fog);
    Fog.output.connectTo(FragmentOutput.rgb);

    // Output nodes
    nodeMaterial.addOutputNode(VertexOutput);
    nodeMaterial.addOutputNode(FragmentOutput);
    nodeMaterial.build();

    return nodeMaterial;
}

export async function terrainMaterial2(scene: Scene, opts: ITerrainMaterialOptions) {
    let mat = new NodeMaterial("Terrain", scene);

    await mat.loadAsync("Materials/TerrainBlendMaterial.json");

    mat.getTextureBlocks().forEach(block => {
        console.log(block.name);
    });

    return mat;
}