import * as SECRETS from "../loaders/config";
import { ILocation, tileCenter2coord, ITile } from "../geo/geo";

export function googleSatellite(tile: ITile) {
    //let tile = coord2tile(zoom, loc);
    let tileLatLng = tileCenter2coord(tile);

    let zoom = tile.zoom + 1; // zoom in a bit for more sweet pixels
    let size = `512x512`;

    return googleSatelliteCustom(tileLatLng.lat, tileLatLng.lng, zoom, size)
}

export function googleSatelliteCustom(lat: number, lng: number, zoom: number, size: string) {
    const key = SECRETS.googleMapApiKey;
    let location = `${lat},${lng}`;
    let scale = "2";
    let type = `satellite`;

    return `https://maps.googleapis.com/maps/api/staticmap?center=${location}&zoom=${zoom}&size=${size}&scale=${scale}&maptype=${type}&key=${key}`
}

export function googleSatelliteOverlay(tile: ITile) {
    const key = SECRETS.googleMapApiKey;
    let tileLatLng = tileCenter2coord(tile);
    let location = `${tileLatLng.lat},${tileLatLng.lng}`;
    let zoom = tile.zoom;
    let size = `512x512`;
    let scale = "1";
    let type = `satellite`;
    let format = "jpg-baseline";

    return `https://maps.googleapis.com/maps/api/staticmap?center=${location}&zoom=${zoom}&size=${size}&scale=${scale}&maptype=${type}&key=${key}&format=${format}`
}

// Build from style: https://maps.googleapis.com/maps/api/staticmap?key=YOUR_API_KEY&center=47.60640228832356,-122.34212123041179&zoom=13&format=png&maptype=roadmap&style=feature:administrative%7Cvisibility:off&style=feature:landscape%7Celement:geometry%7Ccolor:0x00ff00&style=feature:landscape%7Celement:labels%7Cvisibility:off&style=feature:poi%7Cvisibility:off&style=feature:road%7Celement:geometry%7Ccolor:0xff0000&style=feature:road%7Celement:labels%7Cvisibility:off&style=feature:transit%7Cvisibility:off&style=feature:water%7Celement:geometry%7Ccolor:0x0000ff&style=feature:water%7Celement:labels%7Cvisibility:off&size=480x360
export function terrainUrl(tile: ITile) {
    let roadColor = "00FF00";
    let groundColor = "FF0000";
    let waterColor = "0000FF";

    let styles = [
        `feature:administrative%7Cvisibility:off`,
        `feature:landscape%7Celement:geometry%7Ccolor:0x${groundColor}`,
        `feature:landscape%7Celement:labels%7Cvisibility:off`,
        `feature:poi%7Cvisibility:off`,
        `feature:road%7Celement:geometry%7Ccolor:0x${roadColor}`,
        `feature:road%7Celement:labels%7Cvisibility:off`,
        `feature:transit%7Cvisibility:off`,
        `feature:water%7Celement:geometry%7Ccolor:0x${waterColor}`,
        `feature:water%7Celement:labels%7Cvisibility:off`,
    ];

    let tileLatLng = tileCenter2coord(tile);

    const key = SECRETS.googleMapApiKey;
    let location = `${tileLatLng.lat},${tileLatLng.lng}`;
    let zoom = tile.zoom + 1; // zoom in a bit for more sweet pixels
    let size = `512x512`;
    let scale = "2";
    let type = `roadmap`;
    let styleQueryPart = styles.join("&style=");

    return `https://maps.googleapis.com/maps/api/staticmap?center=${location}&zoom=${zoom}&size=${size}&scale=${scale}&maptype=${type}&style=${styleQueryPart}&key=${key}`
}

export interface IGeoResult {
    address: string,
    coordinates: ILocation
}
export async function geoLocate(input: string): Promise<IGeoResult> {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(input)}&key=${SECRETS.googleMapApiKey}&region=AU`

    let placesResponse = await fetch(url);
    let geocode = await placesResponse.json();

    if (geocode.status !== "OK") {
        return null;
    }

    let result = geocode.results;
    let firstResult = result[0];
    let address = firstResult.formatted_address;
    let latLng: ILocation = firstResult.geometry.location

    return {
        address: address,
        coordinates: latLng
    }
}