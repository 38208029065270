import React from 'react';
import styles from './ApplyButton.module.scss';
import { ReactComponent as UpdateIcon } from '../../icons/update.svg';
import { Spinner } from '../../spinner/Spinner';

interface IApplyButtonProps {
    onClick: () => void;
    hidden?: boolean;
    isLoadingYard: boolean;
}

export const ApplyButton: React.FC<IApplyButtonProps> = (props) => {
    return (
        <button onClick={() => !props.isLoadingYard && props.onClick()} hidden={props.hidden} className={styles.root}>
            {props.isLoadingYard ? (
                <Spinner />
            ) : (
                    <>
                        <UpdateIcon className={styles.icon} />
                        <span>Apply</span>
                    </>
                )}
        </button>
    );
}
