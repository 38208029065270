import { ActionManager, Axis, ExecuteCodeAction, PointerEventTypes, Vector3, Animation, Matrix, PointerInfo, PointerInfoPre, UniversalCamera, FreeCameraDeviceOrientationInput, Quaternion } from "babylonjs";
import { totalWorldBoundingInfo } from "../utils";
import { FarmScene } from "./farmScene";

export interface IFirstPersonCameraConfig {
    viewDistance: number;
    minimumDistanceFromGround: number;
}

interface IMultiTouchPanPosition {
    x: number;
    y: number;
}

export class FirstPersonCamera {
    private farmScene: FarmScene;
    private camera: UniversalCamera;
    private headHeight: number = 1.8;

    public panningSensibilityX = 25.0;
    public panningSensibilityY = 35.0;
    public angularSensibilityX = 1000.0;
    public angularSensibilityY = 1000.0;
    public pinchPrecision = 12.0;
    public pinchInwards = true;

    private twoFingerActivityCount = 0;
    private twoFingerActivityDecisionBuffer = 20;
    private initialFingerDistance = 0;
    private intialFingerCentroid: Vector3 = null;
    private isPinching = false;
    private isPanning = false;

    private pointA = null;
    private pointB = null;
    private pinchSquaredDistance = 0;
    private previousPinchSquaredDistance = 0;
    private multiTouchPanPosition: IMultiTouchPanPosition = null;
    private previousMultiTouchPanPosition: IMultiTouchPanPosition = null;

    private isRotating = false;
    private deviceOrientationInput: FreeCameraDeviceOrientationInput = null;
    private touchRotation: Vector3 = null;
    private previousTouchRotation: Vector3 = null;
    private touchAngularSensibilityX = 2000.0;
    private touchAngularSensibilityY = 2000.0;

    constructor(farmScene: FarmScene, config: IFirstPersonCameraConfig) {
        this.farmScene = farmScene;

        this.camera = new UniversalCamera("UniversalCamera", Vector3.Zero(), this.farmScene.scene);
        this.camera.minZ = 0.1;
        this.camera.maxZ = config.viewDistance;
        this.camera.inertia = 0.7;
        this.camera.speed = 0.5;
        this.camera.angularSensibility = 600;
        this.camera.touchAngularSensibility = 1000;
        this.camera.ellipsoid = new Vector3(0.5, config.minimumDistanceFromGround / 2, 0.5);
        this.camera.checkCollisions = true;
        this.camera.attachControl(farmScene.canvas, true);
        this.camera.inputs.removeByType("FreeCameraTouchInput");

        // Start on a fresh page load with camera elevated and looking at the world center
        this.camera.position = new Vector3(0, 10, 25);
        this.camera.setTarget(Vector3.Zero());

        this.farmScene.scene.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnKeyDownTrigger, e => {
            if (e.sourceEvent.keyCode == 16) {
                this.camera.speed = 2;
            }
            if ([37, 38, 39, 40].includes(e.sourceEvent.keyCode)) {
                // stop the camera animating on arrow key presses
                this.cancelCameraAnimation();
            }
        }));

        this.farmScene.scene.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnKeyUpTrigger, e => {
            if (e.sourceEvent.keyCode == 16) {
                this.camera.speed = 0.5;
            }
        }));

        if (typeof DeviceOrientationEvent === "function" && typeof DeviceOrientationEvent.requestPermission === "function") {
            DeviceOrientationEvent.requestPermission()
                .then((result) => {
                    if (result === "granted") {
                        this.attachDeviceOrientationInput();
                    }
                });
        } else {
            // Not iOS, just attach the device orientation input
            this.attachDeviceOrientationInput();
        }

        this.farmScene.scene.onPrePointerObservable.add((pointerInfo) => this.moveAlongZAxisOnScrollWheel(pointerInfo, config), PointerEventTypes.POINTERWHEEL, false);
        this.farmScene.scene.onPointerObservable.add((pointerInfo) => this.registerPointer(pointerInfo), PointerEventTypes.POINTERDOWN);
        this.farmScene.scene.onPointerObservable.add((pointerInfo) => this.unregisterPointer(pointerInfo), PointerEventTypes.POINTERUP);
        this.farmScene.scene.onPointerObservable.add((pointerInfo) => {
            this.onTouch(pointerInfo);
            this.onMultiTouch(pointerInfo, config);
        }, PointerEventTypes.POINTERMOVE);
    }

    public toggleDeviceOrientationInput() {
        if (this.deviceOrientationInput) {
            this.camera.inputs.remove(this.deviceOrientationInput);
            this.deviceOrientationInput = null;
            let previousRotation = this.camera.rotationQuaternion.toEulerAngles();
            this.camera.rotationQuaternion = Quaternion.FromEulerAngles(previousRotation.x, previousRotation.y, 0);
        } else {
            this.attachDeviceOrientationInput();
        }
    }

    private attachDeviceOrientationInput() {
        this.deviceOrientationInput = new FreeCameraDeviceOrientationInput();
        this.camera.inputs.add(this.deviceOrientationInput);
    }

    public setAsActiveCamera() {
        this.farmScene.scene.activeCamera = this.camera;
    }

    public setTarget(value: Vector3) {
        this.camera.setTarget(value);
    }

    public moveTo(position: Vector3, backOffDistance?: number, minimumSpeed?: number, maximumTime?: number) {
        if (backOffDistance) {
            // go backOffDistance meters back from the destination position
            const movement = position.subtract(this.camera.position);

            if (movement.length() > backOffDistance) {
                let normalized = movement.normalizeToNew()
                    .multiplyByFloats(backOffDistance, backOffDistance, backOffDistance);
                position.subtractInPlace(normalized);
            }
            else {
                return;
            }
        }

        // ensure we stay at roughly head-height
        position.y = Math.max(position.y, this.farmScene.level.queryHeight(position.x, position.z) + this.headHeight);

        if (minimumSpeed) {
            // fly over to the new position
            let animationSeconds;

            const distance = this.camera.position.subtract(position).length();
            const speed = distance / maximumTime;

            if (speed < minimumSpeed) {
                animationSeconds = distance / minimumSpeed;
            }
            else {
                // speed is good
                animationSeconds = maximumTime;
            }

            const positionAnimation = new Animation("move camera", "position", 60, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
            const keyFrames = [{ frame: 0, value: this.camera.position.clone() }, { frame: 60 * animationSeconds, value: position }];
            positionAnimation.setKeys(keyFrames);
            this.camera.animations.push(positionAnimation);
            this.camera.getScene().beginAnimation(this.camera, 0, 60, false);
        }
        else {
            this.camera.position = position;
        }
    }

    public resetPosition(): Vector3[] {
        let yardBounds = totalWorldBoundingInfo(this.farmScene.yard);
        let yardBox = yardBounds.boundingBox;
        let distanceToFurthestYardEdge = Math.max(yardBox.extendSize.z, yardBox.extendSize.x);
        let offset = new Vector3(0, distanceToFurthestYardEdge, distanceToFurthestYardEdge * -1.5);

        offset.rotateByQuaternionToRef(this.farmScene.yard.rotationQuaternion, offset);
        this.camera.position = this.farmScene.yard.position.clone();
        this.camera.position.addInPlace(offset);

        let lookPositionOffset = offset.scale(0.1);
        let initialLookPosition = yardBox.center.clone().addInPlace(lookPositionOffset);
        initialLookPosition.y = this.farmScene.level.queryHeight(initialLookPosition.x, initialLookPosition.z);
        this.camera.setTarget(initialLookPosition);

        return [this.camera.position, this.camera.rotation];
    }

    private cancelCameraAnimation = function () {
        this.camera.getScene().stopAnimation(this.camera);
    }.bind(this);

    private moveAlongZAxisOnScrollWheel(pointerInfo: PointerInfoPre, config: IFirstPersonCameraConfig) {
        let event = pointerInfo.event as WheelEvent;

        if (event.deltaY) {
            this.cancelCameraAnimation();
            let direction = this.camera.getDirection(Axis.Z).scale(-Math.sign(event.deltaY));

            let camPosition = this.camera.position;
            camPosition.addInPlace(direction);
            camPosition.y = Math.max(camPosition.y, this.farmScene.level.queryHeight(camPosition.x, camPosition.z) + config.minimumDistanceFromGround);
        }
    }

    private registerPointer(pointerInfo: PointerInfo) {
        let event = pointerInfo.event as PointerEvent;

        if (this.pointA === null) {
            this.pointA = {
                x: event.clientX,
                y: event.clientY,
                pointerId: event.pointerId,
                type: event.pointerType
            };
        } else if (this.pointB === null) {
            this.pointB = {
                x: event.clientX,
                y: event.clientY,
                pointerId: event.pointerId,
                type: event.pointerType
            };
        }

        if (this.pointA && this.pointB) {
            let distX = this.pointA.x - this.pointB.x;
            let distY = this.pointA.y - this.pointB.y;
            this.initialFingerDistance = (distX * distX) + (distY * distY);
            this.intialFingerCentroid = new Vector3(this.pointA.x, this.pointA.y, 1).add(new Vector3(this.pointB.x, this.pointB.y, 1)).scale(0.5);
        }

        this.isRotating = this.pointA && !this.pointB;
    }

    private unregisterPointer(pointerInfo: PointerInfo) {
        let event = pointerInfo.event as PointerEvent;

        // According to Babylon docs, on iOS there is a bug that necessitates clearing the entire pointer list, or one pointer
        // will always remain active. This fix is applied in the same way Babylon fixes it for the ArcRotateCamera
        if (this.farmScene.scene.getEngine()._badOS) {
            this.pointA = null;
            this.pointB = null;
        }
        else if (this.pointB && this.pointA && this.pointA.pointerId === event.pointerId) {
            this.pointA = this.pointB;
            this.pointB = null;
        }
        else if (this.pointA && this.pointB &&
            this.pointB.pointerId === event.pointerId) {
            this.pointB = null;
        }
        else {
            this.pointA = null;
            this.pointB = null;
        }

        if (!this.pointA && !this.pointB || this.pointA && this.pointB) {
            this.touchRotation = null;
            this.previousTouchRotation = null;
        }

        this.previousPinchSquaredDistance = 0;
        this.previousMultiTouchPanPosition = null;
        this.initialFingerDistance = 0;
        this.intialFingerCentroid = null;
        this.twoFingerActivityCount = 0;
        this.isPinching = false;
        this.isPanning = false;

        // Always unregister rotation so the user has to remove both fingers prior to rotating again
        this.isRotating = false;
    }

    private onTouch(pointerInfo: PointerInfo) {
        let event = pointerInfo.event as PointerEvent;

        if (!this.deviceOrientationInput && this.isRotating) {
            this.touchRotation = new Vector3(-event.clientX, -event.clientY, 0);
            this.computeTouchRotation();
            this.previousTouchRotation = this.touchRotation.clone();
        }
    }

    // Most of the logic for multi touch input was replicated from Babylon's _pointerInput logic for the BaseCameraPointersInput
    // https://github.com/BabylonJS/Babylon.js/blob/e7572548cd22deb613c87803b3f31f90f6387938/src/Cameras/Inputs/BaseCameraPointersInput.ts#L192
    private onMultiTouch(pointerInfo: PointerInfo, config: IFirstPersonCameraConfig) {
        let event = pointerInfo.event as PointerEvent;

        if (this.pointA && this.pointB) {
            this.twoFingerActivityCount++;

            let ed = (this.pointA.pointerId === event.pointerId) ? this.pointA : this.pointB;
            ed.x = event.clientX;
            ed.y = event.clientY;

            let distX = this.pointA.x - this.pointB.x;
            let distY = this.pointA.y - this.pointB.y;
            this.pinchSquaredDistance = (distX * distX) + (distY * distY);

            this.multiTouchPanPosition = {
                x: (this.pointA.x + this.pointB.x) / 2,
                y: (this.pointA.y + this.pointB.y) / 2,
            };

            if (this.twoFingerActivityCount > this.twoFingerActivityDecisionBuffer) {
                // Decide after a period whether we're pinching or panning
                if (!this.isPinching && !this.isPanning) {
                    let fingerSeparation = Math.abs(Math.sqrt(this.pinchSquaredDistance) - Math.sqrt(this.initialFingerDistance));
                    let fingerMovement = Vector3.Distance(this.intialFingerCentroid, new Vector3(this.pointA.x, this.pointA.y, 1).add(new Vector3(this.pointB.x, this.pointB.y, 1)).scale(0.5));
                    this.isPinching = fingerSeparation > fingerMovement;
                    this.isPanning = !this.isPinching;
                }

                if (this.isPinching) {
                    this.computePinchZoom(config);
                } else if (this.isPanning) {
                    this.computeMultiTouchPanning(config);
                }
            } else {
                // Perform both pinching and panning at first
                this.computePinchZoom(config);
                this.computeMultiTouchPanning(config);
            }

            this.previousMultiTouchPanPosition = this.multiTouchPanPosition;
            this.previousPinchSquaredDistance = this.pinchSquaredDistance;
        }
    }

    // Most of the logic for pinch-to-zoom was replicated from Babylon's _computePinchZoom logic for the ArcRotateCamera
    // https://github.com/BabylonJS/Babylon.js/blob/e7572548cd22deb613c87803b3f31f90f6387938/src/Cameras/Inputs/arcRotateCameraPointersInput.ts#L124
    private computePinchZoom(config: IFirstPersonCameraConfig) {
        if (this.previousPinchSquaredDistance && this.pinchSquaredDistance) {
            this.cancelCameraAnimation();

            let centroid = new Vector3(this.pointA.x, this.pointA.y, 1).add(new Vector3(this.pointB.x, this.pointB.y, 1)).scale(0.5);
            let target = Vector3.Unproject(
                centroid,
                this.farmScene.scene.getEngine().getRenderWidth(),
                this.farmScene.scene.getEngine().getRenderHeight(),
                Matrix.Identity(), this.farmScene.scene.getViewMatrix(),
                this.farmScene.scene.getProjectionMatrix()
            );

            let direction = target.normalize().scale(
                (this.pinchSquaredDistance - this.previousPinchSquaredDistance) /
                (this.pinchPrecision * (this.pinchInwards ? 1 : -1) * (this.angularSensibilityX + this.angularSensibilityY) / 2));

            let camPosition = this.camera.position;
            camPosition.addInPlace(direction);
            camPosition.y = Math.max(camPosition.y, this.farmScene.level.queryHeight(camPosition.x, camPosition.z) + config.minimumDistanceFromGround);
        }
    }

    // Most of the logic for two-finger swipe was replicated from Babylon's _computeMultiTouchPanning logic for the ArcRotateCamera
    // https://github.com/BabylonJS/Babylon.js/blob/e7572548cd22deb613c87803b3f31f90f6387938/src/Cameras/Inputs/arcRotateCameraPointersInput.ts#L108
    private computeMultiTouchPanning(config: IFirstPersonCameraConfig) {
        if (this.multiTouchPanPosition && this.previousMultiTouchPanPosition) {
            this.cancelCameraAnimation();

            let moveDeltaX = this.multiTouchPanPosition.x - this.previousMultiTouchPanPosition.x;
            let moveDeltaY = this.multiTouchPanPosition.y - this.previousMultiTouchPanPosition.y;

            let directionX = this.camera.getDirection(Axis.X).scale(-moveDeltaX / this.panningSensibilityX);
            let directionY = this.camera.getDirection(Axis.Y).scale(moveDeltaY / this.panningSensibilityY);

            let camPosition = this.camera.position;
            camPosition.addInPlace(directionX);
            camPosition.addInPlace(directionY);
            camPosition.y = Math.max(camPosition.y, this.farmScene.level.queryHeight(camPosition.x, camPosition.z) + config.minimumDistanceFromGround);
        }
    }

    private computeTouchRotation() {
        if (this.touchRotation && this.previousTouchRotation) {
            this.cancelCameraAnimation();

            this.camera.cameraRotation.x += (this.touchRotation.y - this.previousTouchRotation.y) / this.touchAngularSensibilityX;
            this.camera.cameraRotation.y += (this.touchRotation.x - this.previousTouchRotation.x) / this.touchAngularSensibilityY;
        }
    }
}
