import { Scene, StandardMaterial, Color3, Texture, Vector3, GroundMesh, SceneLoader, Vector2, MeshBuilder, ExecuteCodeAction, ActionManager, PickingInfo } from "babylonjs";
import { tileSizeMetres, coord2tile, ITile, IMapScene, tileCenter2coord, isValidLocation, defaultLocationMapScene } from "../geo/geo";
import { terrainMaterial } from "../materials/TerrainBlendMaterial";
import { buildGround } from "./terrainGround";
import { loadTileTexture, elevationUrl, terrainMapUrl, imageryUrl } from "../loaders";
import { RenderedWorld } from "./RenderedWorld";

export interface ITileDefinition {
    tile: ITile
    size: number
    tileOffset: Vector2
    offsetFromCenterTile: Vector3
}

function getNamedGroundMeshes(allTiles: IRenderedTile[]) {
    // top left    | top middle    | top right
    // middle left | middle middle | middle right
    // bottom left | bottom middle | bottom right
    const tl = allTiles.find(t => t.tileOffset.x == 1 && t.tileOffset.y == -1).groundMesh;
    const tm = allTiles.find(t => t.tileOffset.x == 0 && t.tileOffset.y == -1).groundMesh;
    const tr = allTiles.find(t => t.tileOffset.x == -1 && t.tileOffset.y == -1).groundMesh;
    const ml = allTiles.find(t => t.tileOffset.x == 1 && t.tileOffset.y == 0).groundMesh;
    const mm = allTiles.find(t => t.tileOffset.x == 0 && t.tileOffset.y == 0).groundMesh;
    const mr = allTiles.find(t => t.tileOffset.x == -1 && t.tileOffset.y == 0).groundMesh;
    const bl = allTiles.find(t => t.tileOffset.x == 1 && t.tileOffset.y == 1).groundMesh;
    const bm = allTiles.find(t => t.tileOffset.x == 0 && t.tileOffset.y == 1).groundMesh;
    const br = allTiles.find(t => t.tileOffset.x == -1 && t.tileOffset.y == 1).groundMesh;

    return [tl, tm, tr, ml, mm, mr, bl, bm, br];
}

export function joinOuterTileEdgesToCenter(allTiles: IRenderedTile[]) {
    const [tl, tm, tr, ml, mm, mr, bl, bm, br] = getNamedGroundMeshes(allTiles);

    const edgeLength = mm.subdivisions + 1;

    const getYComponentIndex = function (x: number, y: number): number {
        return y * edgeLength * 3 + x * 3 + 1;
    }

    mm.updateMeshPositions((center) => {
        tl.updateMeshPositions((corner) => corner[getYComponentIndex(edgeLength - 1, edgeLength - 1)] = center[getYComponentIndex(0, 0)]);
        bl.updateMeshPositions((corner) => corner[getYComponentIndex(edgeLength - 1, 0)] = center[getYComponentIndex(0, edgeLength - 1)]);
        tr.updateMeshPositions((corner) => corner[getYComponentIndex(0, edgeLength - 1)] = center[getYComponentIndex(edgeLength - 1, 0)]);
        br.updateMeshPositions((corner) => corner[getYComponentIndex(0, 0)] = center[getYComponentIndex(edgeLength - 1, edgeLength - 1)]);

        ml.updateMeshPositions((side) => {
            for (let i = 0; i < edgeLength; i++) {
                const leftIndex = getYComponentIndex(edgeLength - 1, i); // right edge
                const rightIndex = getYComponentIndex(0, i); // left edge
                side[leftIndex] = center[rightIndex];
            }
        });

        mr.updateMeshPositions((side) => {
            for (let i = 0; i < edgeLength; i++) {
                const leftIndex = getYComponentIndex(edgeLength - 1, i); // right edge
                const rightIndex = getYComponentIndex(0, i); // left edge
                side[rightIndex] = center[leftIndex];
            }
        });

        tm.updateMeshPositions(side => {
            for (let x = 0; x < edgeLength; x++) {
                const topIndex = edgeLength * (edgeLength - 1) * 3 + x * 3 + 1; // bottom edge
                const bottomIndex = x * 3 + 1; // top edge
                side[topIndex] = center[bottomIndex];
            }
        });

        bm.updateMeshPositions(side => {
            for (let x = 0; x < edgeLength; x++) {
                const topIndex = edgeLength * (edgeLength - 1) * 3 + x * 3 + 1; // bottom edge
                const bottomIndex = x * 3 + 1; // top edge
                side[bottomIndex] = center[topIndex];
            }
        });
    });

    allTiles.map((tile) => {
        tile.groundMesh.refreshBoundingInfo();
        tile.groundMesh.updateCoordinateHeights();
    });
}

function joinGroundTileEdges(allTiles: IRenderedTile[]) {
    const [tl, tm, tr, ml, mm, mr, bl, bm, br] = getNamedGroundMeshes(allTiles);

    const edgeLength = mm.subdivisions + 1;

    const getYComponentIndex = function (x: number, y: number): number {
        return y * edgeLength * 3 + x * 3 + 1;
    }

    const joinYEdge = function (left: GroundMesh, right: GroundMesh) {
        left.updateMeshPositions(leftVertices => {
            right.updateMeshPositions(rightVertices => {
                for (let y = 0; y < edgeLength; y++) {
                    const leftIndex = getYComponentIndex(edgeLength - 1, y); // right edge
                    const rightIndex = getYComponentIndex(0, y); // left edge
                    rightVertices[rightIndex] = leftVertices[leftIndex];
                }
            });
        });
    };

    let joinXEdge = function (top: GroundMesh, bottom: GroundMesh) {
        top.updateMeshPositions(topVertices => {
            bottom.updateMeshPositions(bottomVertices => {
                for (let x = 0; x < edgeLength; x++) {
                    const topIndex = edgeLength * (edgeLength - 1) * 3 + x * 3 + 1; // bottom edge
                    const bottomIndex = x * 3 + 1; // top edge
                    bottomVertices[bottomIndex] = topVertices[topIndex];
                }
            });
        });
    }

    joinYEdge(tl, tm);
    joinYEdge(tm, tr);
    joinYEdge(ml, mm);

    joinYEdge(mm, mr)
    joinYEdge(bl, bm);
    joinYEdge(bm, br);

    joinXEdge(tl, ml);
    joinXEdge(tm, mm);
    joinXEdge(tr, mr);

    joinXEdge(ml, bl);
    joinXEdge(mm, bm);
    joinXEdge(mr, br);

    allTiles.map((tile) => {
        tile.groundMesh.refreshBoundingInfo();
        tile.groundMesh.updateCoordinateHeights();
    });
}

export interface IRenderedTile extends ITileDefinition {
    groundMesh: GroundMesh
    elevationTexture: Texture
    imageryTexture: Texture
    terrainMapTexture: Texture
}

export async function makeTile(scene: Scene, tileDef: ITileDefinition): Promise<IRenderedTile> {
    let elevationTexture = await loadTileTexture(scene, elevationUrl(tileDef), false);
    let imageryTexture = await loadTileTexture(scene, imageryUrl(tileDef), true);
    let terrainMapTexture = await loadTileTexture(scene, terrainMapUrl(tileDef), true);

    let ground = await buildGround(elevationTexture, tileDef, scene);
    ground.material = await terrainMaterial(scene, terrainMapTexture, tileDef);

    ground.receiveShadows = true;
    ground.checkCollisions = true;

    let groundPos = tileDef.offsetFromCenterTile;
    ground.position = groundPos.clone();

    return {
        ...tileDef,
        groundMesh: ground,
        imageryTexture,
        elevationTexture,
        terrainMapTexture
    };
}

export async function createCenterTile(scene: Scene, tile: ITile): Promise<IRenderedTile> {
    let sizeMetres = tileSizeMetres(tileCenter2coord(tile), tile.zoom);

    let tileDef: ITileDefinition = {
        size: sizeMetres,
        tile: tile,
        tileOffset: Vector2.Zero(),
        offsetFromCenterTile: Vector3.Zero()
    };

    let renderedTile = await makeTile(scene, tileDef);
    return renderedTile;
}

export async function createOffsetTile(scene: Scene, center: IRenderedTile, offset: Vector2): Promise<IRenderedTile> {
    // Somewhere, I've gone wrong with x/y vs lat/lng, and I don't understand it.
    // This works.
    let newTile = {
        zoom: center.tile.zoom,
        x: center.tile.x - offset.x,
        y: center.tile.y + offset.y
    };

    let tileDef: ITileDefinition = {
        ...center,
        tile: newTile,
        tileOffset: offset,
        offsetFromCenterTile: new Vector3(offset.x, 0, offset.y).scale(-center.size)
    };

    let renderedTile = await makeTile(scene, tileDef);
    return renderedTile;
};

function setupGroundDoubleClick(scene: Scene, ground: GroundMesh, onGroundPicked: (info: PickingInfo) => void) {
    if (!onGroundPicked) return;
    ground.isPickable = true;
    ground.actionManager = new ActionManager(scene);
    ground.actionManager.registerAction(
        new ExecuteCodeAction(
            {
                trigger: ActionManager.OnDoublePickTrigger,
            }, () => onGroundPicked(scene.pick(scene.pointerX, scene.pointerY))
        )
    );
}

export async function createLevel(scene: Scene, mapScene: IMapScene, onGroundPicked?: (info: PickingInfo) => void): Promise<RenderedWorld> {
    if (!isValidLocation(mapScene)) Object.assign(mapScene, defaultLocationMapScene);

    let centerTile = coord2tile(mapScene.zoom, mapScene);

    let center = await createCenterTile(scene, centerTile);
    //return new RenderedWorld(center, []);
    let offsets: Vector2[] = [].concat(...[-1, 0, 1].map(dx => [-1, 0, 1].map(dy => (new Vector2(dx, dy)))))
        .filter(o => o.x != 0 || o.y != 0);

    let surrounding = await Promise.all(offsets.map(async o => {
        let surroundingTile = await createOffsetTile(scene, center, o);
        return surroundingTile;
    }));

    let allTiles = [center].concat(...surrounding);

    joinGroundTileEdges(allTiles);

    allTiles.forEach(tile => {
        setupGroundDoubleClick(scene, tile.groundMesh, onGroundPicked);
    });

    console.log(`Loaded ${allTiles.length} tiles, centered at ${mapScene.lat},${mapScene.lng}, zoom=${mapScene.zoom}. Tile size is ${allTiles[0].size}`);

    var world = new RenderedWorld(center, surrounding);

    return world;
}

