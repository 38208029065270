import { IFarmSceneOptions, Quality, allQualities } from "./farmScene/farmScene";
import { defaultLocationMapScene } from "./geo/geo";
import { getBrands } from "./loaders/catalog";

export const defaultFarmScene: IFarmSceneOptions = {
    location: { ...defaultLocationMapScene, lat: NaN, lng: NaN, zoom: 15 },
    yardName: defaultLocationMapScene.defaultYardName,
    treesCastShadows: true,
    yardCastsShadows: true,
    quality: "Standard",
    livestockEnabled: false,
    remoteControl: false,
    brand: "CATTLEKING",
    heading: 0
}

export function saveAndReload(opts: IFarmSceneOptions) {
    saveToHash(opts);
}

export function buildHash(opts: IFarmSceneOptions) {
    let hash = `v1/${opts.yardName}/${opts.location.lat || ""}/${opts.location.lng || ""}`;

    if (opts.brand !== null) {
        hash = hash + `/brand=${opts.brand}`;
    }

    if (opts.quality !== defaultFarmScene.quality) {
        hash = hash + `/quality=${opts.quality}`;
    }

    if (opts.heading !== defaultFarmScene.heading) {
        hash = hash + `/heading=${opts.heading}`;
    }

    if (opts.remoteControl) {
        hash = hash + '/remotecontrol=true';
    }

    return hash;
}

export function saveToHash(opts: IFarmSceneOptions) {
    window.location.hash = buildHash(opts);
}

export function parseCurrentHash(): IFarmSceneOptions {
    let [version, yardName, lat, lng, ...remainingParams] = window.location.hash.split("/");

    if (version === "#vr") {
        defaultFarmScene.remoteControl = true;
    }
    if (version != "#v1") {

        saveToHash(defaultFarmScene);
        return defaultFarmScene;
    }

    let variableOptions: { [key: string]: string } = remainingParams.reduce((prev, component) => {
        let [key, value] = component.split("=");
        prev[key] = value;
        return prev;
    }, {});

    let sceneOptions: IFarmSceneOptions = {
        location: {
            zoom: parseInt(variableOptions["zoom"]) || defaultFarmScene.location.zoom,
            lat: parseFloat(lat),
            lng: parseFloat(lng),
        },
        yardName: decodeURIComponent(yardName),
        treesCastShadows: defaultFarmScene.treesCastShadows,
        yardCastsShadows: defaultFarmScene.yardCastsShadows,
        quality: parseQuality(variableOptions["quality"]),
        livestockEnabled: defaultFarmScene.livestockEnabled,
        remoteControl: variableOptions["remotecontrol"] === "true",
        brand: parseBrand(variableOptions["brand"]),
        heading: Number(variableOptions["heading"]) || defaultFarmScene.heading
    }
    // console.log("Read Scene options: ", sceneOptions)
    return sceneOptions;
}

function parseQuality(value: string) {
    let qualityValue = value as Quality;

    return allQualities.indexOf(qualityValue) > -1
        ? qualityValue
        : defaultFarmScene.quality;
}

function parseBrand(brandValue: string) {
    const loadedBrands = getBrands();

    return !loadedBrands || (!!brandValue && !!loadedBrands && loadedBrands.some(b => b.Brands.some(x => x.toUpperCase() === brandValue?.toUpperCase())))
        ? brandValue
        : defaultFarmScene.brand;
}
