import { Scene, Texture, CubeTexture } from "babylonjs";

export function skyboxCubeTexture(scene: Scene) {
    let existingTexture = scene.textures.find(t => t.name == "Skybox")
    if (!existingTexture) {
        existingTexture = new CubeTexture("Textures/skyboxes/skybox", scene);
        existingTexture.name = "Skybox";
        existingTexture.coordinatesMode = Texture.CUBIC_MODE;
    }

    return existingTexture;
}

export function reflectSkybox(scene: Scene, level: number) {
    let texName = `Skybox_${level}`;

    let existingTexture = scene.textures.find(t => t.name == texName)
    if (!existingTexture) {
        existingTexture = skyboxCubeTexture(scene).clone();
        existingTexture.name = texName;
        existingTexture.level = level;
    }

    return existingTexture;
}