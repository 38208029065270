import React from 'react';
import cn from 'classnames';
import styles from './Callout.module.scss';

export type CalloutPosition = "top" | "bottom" | "left" | "right" | "top-left" | "top-right";

interface ICallout {
    position: CalloutPosition;
    className: string;
}

export const Callout: React.FC<ICallout> = props => {
    return <div className={cn(styles.callout, styles[props.position], props.className)}>
        {props.children}
    </div>;
}