import React from 'react'
import styles from './Header.module.scss';
import { ReactComponent as PhoneIcon } from '../icons/phone.svg';
import { ReactComponent as BlueScopeIcon } from '../icons/BlueScope.svg';

export interface IHeader {
    onHelp: () => void;
}

export const Header: React.FC<IHeader> = (props) => {
    return (
        <div className={styles.header}>
            <div className={styles.bluescope}>
                <BlueScopeIcon id="blue-scope-logo" />
                <span>Business of BlueScope</span>
            </div>
            <div className={styles.help}>
                <a onClick={props.onHelp}>Help</a>
                <a href="tel:1300306304" className={styles.phone}>
                    <PhoneIcon />
                    1300 306 204
                </a>
            </div>
        </div>
    )
}
