// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2WonmIJlAdhen5U15l6uRH{position:absolute;top:0;bottom:0;left:0;right:0;background-color:var(--color-corporate-blue-overlay-hidden);z-index:1;pointer-events:none;transition:background-color .5s}._2WonmIJlAdhen5U15l6uRH._175-uep_fydskztr54i_LY{background-color:var(--color-corporate-blue-overlay);pointer-events:auto}._2WonmIJlAdhen5U15l6uRH.qGpIhdBJ1gjhJIItbZ0MN{top:calc(var(--toolbar-height) + var(--header-height))}._3KLOvhL9FH58xxqdqXiPL4{position:absolute;top:0;height:calc(var(--toolbar-height) + var(--header-height));left:0;right:0;background-color:transparent;z-index:1;pointer-events:auto}", "",{"version":3,"sources":["webpack://./src/components/overlay/Overlay.module.scss"],"names":[],"mappings":"AAAA,yBACI,iBAAA,CACA,KAAA,CAAA,QAAA,CAAA,MAAA,CAAA,OAAA,CACA,2DAAA,CACA,SAAA,CAEA,mBAAA,CACA,+BAAA,CAEA,iDACI,oDAAA,CACA,mBAAA,CAGJ,+CACI,sDAAA,CAIR,yBACI,iBAAA,CACA,KAAA,CAAA,yDAAA,CAAA,MAAA,CAAA,OAAA,CACA,4BAAA,CACA,SAAA,CACA,mBAAA","sourcesContent":[".root {\n    position: absolute;\n    top: 0; bottom: 0; left: 0; right: 0;\n    background-color: var(--color-corporate-blue-overlay-hidden);\n    z-index: 1;\n\n    pointer-events: none;\n    transition: background-color 0.5s;\n\n    &.open {\n        background-color: var(--color-corporate-blue-overlay);\n        pointer-events: auto;\n    }\n\n    &.showToolbar {\n        top: calc(var(--toolbar-height) + var(--header-height));\n    }\n}\n\n.invisibleToolbarOverlay {\n    position: absolute;\n    top: 0; height: calc(var(--toolbar-height) + var(--header-height)); left: 0; right: 0;\n    background-color: transparent;\n    z-index: 1;\n    pointer-events: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_2WonmIJlAdhen5U15l6uRH",
	"open": "_175-uep_fydskztr54i_LY",
	"showToolbar": "qGpIhdBJ1gjhJIItbZ0MN",
	"invisibleToolbarOverlay": "_3KLOvhL9FH58xxqdqXiPL4"
};
export default ___CSS_LOADER_EXPORT___;
