import { Scene, StandardMaterial, Color3, CubeTexture, Texture, Material, PBRMaterial } from "babylonjs";
import { Quality } from "../farmScene/farmScene";
import { getOrCreateMaterial } from "./materialHelpers";
import { reflectSkybox, skyboxCubeTexture } from "../loaders/textures";

function basicStandardMaterial(scene: Scene, name: string, diffuseTexture: string, bumpTexture: string, roughness: number) {
    let mat = new StandardMaterial(name, scene);
    mat.diffuseTexture = new Texture(diffuseTexture, scene);
    // Disabling the normal map for now as the 3DS Max export process doesn't include UV Maps, models show up all black
    // if (bumpTexture)    
    //     mat.bumpTexture = new Texture(bumpTexture, scene);
    mat.reflectionTexture = reflectSkybox(scene, 0.25);
    mat.roughness = roughness;
    return mat;
}
function basicPbrMaterial(scene: Scene, name: string, albedoTexture: string, bumpTexture: string, roughness: number, metallic: number) {
    let mat = new PBRMaterial(name, scene);
    mat.albedoTexture = new Texture(albedoTexture, scene);
    // Disabling the normal map for now as the 3DS Max export process doesn't include UV Maps, models show up all black
    // if (bumpTexture)
    //     mat.bumpTexture = new Texture(bumpTexture, scene);
    mat.roughness = roughness;
    mat.metallic = metallic;
    mat.reflectionTexture = skyboxCubeTexture(scene);
    return mat;
}

export function smoothMetalMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("MetalCorp_SmoothMetal", scene,    
        () => quality == "Standard"
            ? basicStandardMaterial(scene, "MetalCorp_SmoothMetal", "Textures/MetalClean_basecolor.jpg", "Textures/MetalCleanl_normal.jpg", 0.25)
            : basicPbrMaterial(scene, "MetalCorp_SmoothMetal", "Textures/MetalClean_basecolor.jpg", "Textures/MetalCleanl_normal.jpg", 0.45, 1)
    );
}
export function pbrSmoothMetalMaterial(scene: Scene, name: string) {
    let mat = new PBRMaterial(name, scene);
    mat.albedoTexture = new Texture("Textures/MetalClean_basecolor.jpg", scene);
    mat.bumpTexture = new Texture("Textures/MetalCleanl_normal.jpg", scene);
    mat.roughness = 0.25;
    mat.metallic = 1;
    return mat;
}

export function spangledMetalMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("MetalCorp_SpangledMetal", scene,    
        () => quality == "Standard"
            ? basicStandardMaterial(scene, "MetalCorp_SpangledMetal", "Textures/MetalSpangled_BaseColor.jpg", "Textures/MetalSpangledl_normal.jpg", 0.75)
            : basicPbrMaterial(scene, "MetalCorp_SpangledMetal", "Textures/MetalSpangled_BaseColor.jpg", "Textures/MetalSpangledl_normal.jpg", 0.75, 1)
    );
}

export function rubberMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("MetalCorp_Rubber", scene,    
        () => quality == "Standard"
            ? basicStandardMaterial(scene, "MetalCorp_Rubber", "Textures/Rubber.jpg", null, 0.75)
            : basicPbrMaterial(scene, "MetalCorp_Rubber", "Textures/Rubber.jpg", null, 0.75, 1)
    );
}

export function concreteMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("MetalCorp_Concrete", scene,
        () => quality == "Standard"
            // I have no idea why this isn't a 0-1 scale, but roughness seems to work on a 0-10
            ? basicStandardMaterial(scene, "MetalCorp_Concrete", "Textures/Concrete.jpg", null, 10)
            : basicPbrMaterial(scene, "MetalCorp_Concrete", "Textures/Concrete.jpg", null, 10, 0)
    );
}

export function bluePaint(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("MetalCorp_BluePaint", scene,
        () => quality == "Standard"
            ? basicStandardMaterial(scene, "MetalCorp_BluePaint", "Textures/IndustrialSteelPainted_basecolor.jpg", "Textures/IndustrialSteelPainted_normal.jpg", 0.5)
            : basicPbrMaterial(scene, "MetalCorp_BluePaint", "Textures/IndustrialSteelPainted_basecolor.jpg", "Textures/IndustrialSteelPainted_normal.jpg", 0.5, 1)
    );
}

export function yellowPaint(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("MetalCorp_YellowPaint", scene,
        () => quality == "Standard"
            ? basicStandardMaterial(scene, "MetalCorp_YellowPaint", "Textures/IndustrialSteelPaintedYellow_basecolor.jpg", "Textures/IndustrialSteelPainted_normal.jpg", 0.5)
            : basicPbrMaterial(scene, "MetalCorp_YellowPaint", "Textures/IndustrialSteelPaintedYellow_basecolor.jpg", "Textures/IndustrialSteelPainted_normal.jpg", 0.5, 1)
    );
}

export function logoMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("MetalCorp_Logos", scene,
        () => quality == "Standard"
            ? standardLogoMaterial(scene)
            : pbrLogoMaterial(scene)
    );
}

export function standardLogoMaterial(scene: Scene) {
    let mat = basicStandardMaterial(scene, "MetalCorp_Logos", "Textures/MetalCorp_Logos.png", null, 0.25);
    
    let mainTexture = mat.diffuseTexture as Texture;
    mainTexture.level = 0.5;
    mainTexture.vScale = -1
    mainTexture.hasAlpha = true;

    mat.emissiveTexture = mainTexture;
    mat.alphaCutOff = 0.5;
    return mat;
}

export function pbrLogoMaterial(scene: Scene) {
    let mat = basicPbrMaterial(scene, "MetalCorp_Logos", "Textures/MetalCorp_Logos.png", null, 0.25, 0);

    let mainTexture = mat.albedoTexture as Texture;
    mainTexture.vScale = -1
    mainTexture.hasAlpha = true;

    mat.alphaCutOff = 0.5;
    return mat;
}

export function metalcorpLogoMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("metalcorpLogoMaterial", scene,
        () => standardMetalCorpLogoMaterial(scene)
    );
}

export function standardMetalCorpLogoMaterial(scene: Scene) {
    let mat = basicStandardMaterial(scene, "metalcorpLogoMaterial", "Textures/metalcorp_logo.png", null, 0.25);
    
    let mainTexture = mat.diffuseTexture as Texture;
    mainTexture.level = 0.5;
    mainTexture.vScale = -1
    mainTexture.hasAlpha = true;

    mat.emissiveTexture = mainTexture;
    mat.alphaCutOff = 0.5;
    return mat;
}

export function orrconSteelLogoMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("orrconSteelLogoMaterial", scene,
        () => standardOrrconSteelLogoMaterial(scene)
    );
}

export function standardOrrconSteelLogoMaterial(scene: Scene) {
    let mat = basicStandardMaterial(scene, "orrconSteelLogoMaterial", "Textures/orrcon_steel_logo.png", null, 0.25);
    
    let mainTexture = mat.diffuseTexture as Texture;
    mainTexture.level = 0.5;
    mainTexture.vScale = -1
    mainTexture.hasAlpha = true;

    mat.emissiveTexture = mainTexture;
    mat.alphaCutOff = 0.5;
    return mat;
}

export function cattlemasterLogoMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("cattlemasterLogoMaterial", scene,
        () => standardCattlemasterLogoMaterial(scene)
    );
}

export function standardCattlemasterLogoMaterial(scene: Scene) {
    let mat = basicStandardMaterial(scene, "cattlemasterLogoMaterial", "Textures/cattlemaster_logo.png", null, 0.25);
    
    let mainTexture = mat.diffuseTexture as Texture;
    mainTexture.level = 0.5;
    mainTexture.vScale = -1
    mainTexture.hasAlpha = true;

    mat.emissiveTexture = mainTexture;
    mat.alphaCutOff = 0.5;
    return mat;
}

export function classicPlusLogoMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("classicPlusLogoMaterial", scene,
        () => standardClassicPlusLogoMaterial(scene)
    );
}

export function standardClassicPlusLogoMaterial(scene: Scene) {
    let mat = basicStandardMaterial(scene, "classicPlusLogoMaterial", "Textures/classic_plus_logo.png", null, 0.25);
    
    let mainTexture = mat.diffuseTexture as Texture;
    mainTexture.level = 0.5;
    mainTexture.vScale = -1
    mainTexture.hasAlpha = true;

    mat.emissiveTexture = mainTexture;
    mat.alphaCutOff = 0.5;
    return mat;
}

export function hobbymasterLogoMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("hobbymasterLogoMaterial", scene,
        () => standardHobbymasterLogoMaterial(scene)
    );
}

export function standardHobbymasterLogoMaterial(scene: Scene) {
    let mat = basicStandardMaterial(scene, "hobbymasterLogoMaterial", "Textures/hobbymaster_logo.png", null, 0.25);

    let mainTexture = mat.diffuseTexture as Texture;
    mainTexture.level = 0.5;
    mainTexture.vScale = -1
    mainTexture.hasAlpha = true;

    mat.emissiveTexture = mainTexture;
    mat.alphaCutOff = 0.5;
    return mat;
}

export function rampMaterial(scene: Scene, quality: Quality) {
    return smoothMetalMaterial(scene, quality);
}

export function woodMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("MetalCorp_Wood", scene,
        () => quality == "Standard"
            ? standardWoodMaterial(scene)
            : basicPbrMaterial(scene, "MetalCorp_Wood", "Textures/WoodPlanks_BaseColor.jpg", "Textures/WoodPlanks_Normal.jpg", 0.9, 0)
    );
}
function standardWoodMaterial(scene: Scene) {
    let mat = basicStandardMaterial(scene, "MetalCorp_Wood", "Textures/WoodPlanks_BaseColor.jpg", "Textures/WoodPlanks_Normal.jpg", 0.9);

    let mainTexture = mat.diffuseTexture as Texture;
    mainTexture.level = 0.6;

    mat.emissiveTexture = mainTexture;
    mat.reflectionTexture = null
    mat.specularColor = Color3.Black();
    return mat;
}


export function grateMaterial(scene: Scene, quality: Quality) {
    return getOrCreateMaterial("MetalCorp_Grate", scene,
        () => quality == "Standard"
            ? standardGrateMaterial(scene)
            : pbrGrateMaterial(scene)
    );
}
export function standardGrateMaterial(scene: Scene) {
    let mat = basicStandardMaterial(scene, "MetalCorp_Grate", "Textures/Grate.png", null, 0.25);
    
    let mainTexture = mat.diffuseTexture as Texture;
    mainTexture.vScale = -1
    mainTexture.hasAlpha = true;

    mat.alphaCutOff = 0.5;
    return mat;
}

export function pbrGrateMaterial(scene: Scene) {
    let mat = basicPbrMaterial(scene, "MetalCorp_Grate", "Textures/Grate.png", null, 0.25, 0);

    let mainTexture = mat.albedoTexture as Texture;
    mainTexture.vScale = -1
    mainTexture.hasAlpha = true;

    mat.alphaCutOff = 0.5;
    return mat;
}