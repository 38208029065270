import React, { useContext, useEffect, useState } from 'react';
import styles from './Help.module.scss';
import cn from 'classnames';
import { Callout, CalloutPosition } from './Callout';
import { Hint } from './Hint';
import { ReactComponent as RotateIcon } from '../icons/rotate-yard.svg';
import { Options } from '../../App';

interface IHelp {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

class HintData {
    constructor(
        public className: string,
        public position: CalloutPosition,
        public title: string,
        public body: React.ReactNode) {}
}

const allHints = [
    new HintData(styles.location, "top-left", "Change your location", <>Click on the <em>Mini Map</em> to change the location of the farm yard on the map. We'll generate the terrain for the 3D model.</>),
    new HintData(styles.settings, "bottom", "Adjust settings to your needs", <>Select the <em>stock type</em>, <em>working capacity</em>, and <em>brand</em> to find a farm yard that suits your needs.</>),
    new HintData(styles.movement, "top", "Move and fly around", <>On desktop, <em>click and drag</em> with your mouse to look around, and move with the <em>arrow keys</em>, <em>scroll wheel</em> or <em>double-click</em>.</>),
    new HintData(styles.tools, "left", "An immersive experience", <>Enter <em>Immersive Mode</em>, or <em>Virtual Reality</em>, or <em>Full Screen</em> to immerse yourself (on select devices).</>),
    new HintData(styles.rotation, "left", "Spin the yard", <>Use <RotateIcon /> to turn the yard around to a more appropriate angle for your property. You can precisely place it with the <em>Change Location</em> button.</>),
    new HintData(styles.resetView, "left", "Reset the view", <>If you ever get lost or turned around, click the <em>helicopter</em> to go back to the beginning.</>),
];

export const Help: React.FC<IHelp> = props => {
    if (!props.open) 
        return <></>;

    const options = useContext(Options);
    const [currentHint, setCurrentHint] = useState(0);
    const [hints, setHints] = useState<HintData[]>(allHints);
    const [immersiveOptionCount, setImmersiveOptionCount] = useState(0);

    // close the help screen if the window is resized too small
    useEffect(() => {
        const mql = window.matchMedia(`(max-width: ${styles.tabletSmall})`);

        const onChange = (ev: MediaQueryListEvent) => {
            if (ev.matches && props.open) {
                props.setOpen(false);
            }
        }

        mql.addEventListener ? mql.addEventListener('change', onChange) : mql.addListener(onChange);

        return function cleanup() {
            mql.removeEventListener ? mql.removeEventListener('change', onChange) : mql.removeListener(onChange);
        }
    }, [props.setOpen]);

    useEffect(() => {
        setHints(allHints.filter(hint => {
            // don't show the immersive experience hint if there are no immersive options available
            return options.visibleButtons.fullScreen || options.visibleButtons.immersiveMode || options.visibleButtons.vr || hint.className !== styles.tools;
        }));
        setImmersiveOptionCount(
            [options.visibleButtons.fullScreen, options.visibleButtons.immersiveMode, options.visibleButtons.vr]
                .reduce((acc, val) => (val ? 1 : 0) + acc, 0)
        );
    }, [setHints, setImmersiveOptionCount, options.visibleButtons]);

    const setClosed = () => props.setOpen(false);



    return <>
        <Callout position={hints[currentHint].position} className={cn(hints[currentHint].className, styles['immersivecount-' + immersiveOptionCount])}>
            <Hint hintNumber={currentHint + 1} totalHints={hints.length} title={hints[currentHint].title} setClosed={setClosed} next={() => setCurrentHint(currentHint + 1)} prev={() => setCurrentHint(currentHint - 1)}>
                {hints[currentHint].body}
            </Hint>
        </Callout>
{props.children}
    </>;
}