import React from 'react';
import styles from './Hint.module.scss';


interface IHint {
    hintNumber: number;
    totalHints: number;
    title: string;
    setClosed: () => void;
    next: () => void;
    prev: () => void;
}

export const Hint: React.FC<IHint> = props => {
    return <>
        <button className={styles.closeButton} onClick={props.setClosed}>&#x2715;</button>
        <div className={styles.hint}>Hint {props.hintNumber} of {props.totalHints}</div>
        <div className={styles.title}>{props.title}</div>
        <div className={styles.body}>{props.children}</div>
        <div className={styles.navigation}>
            {props.hintNumber == 1 ? <button onClick={() => props.setClosed()}>Skip Tutorial</button> : null}
            {props.hintNumber > 1 ? <button onClick={() => props.prev()}>Previous</button> : null}
            {props.hintNumber < props.totalHints ? <button onClick={() => props.next()} className={styles.next}>Next</button> : null}
            {props.hintNumber == props.totalHints ? <button onClick={props.setClosed} className={styles.next}>Done</button> : null}
        </div>
    </>;
}