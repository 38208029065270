import { Scene, Texture, StandardMaterial, Color3 } from "babylonjs";
import * as BABYLON from "babylonjs";

export function createTreeMaterial(name: string, scene: Scene) {
    let existing = scene.materials.find(m => m.name == name);
    if (existing)
        return existing;

    let diffuseTexture = new Texture(`./Textures/Trees/${name}-Diffuse.jpg`, scene);
    let aoTexture = new Texture(`./Textures/Trees/${name}-AO.jpg`, scene);
    //let aoTexture = new Texture(`Textures/white.png`, scene);
    let maskTexture = new Texture(`./Textures/Trees/${name}-Mask.jpg`, scene);
    maskTexture.level = 2;

    let textures = [diffuseTexture, aoTexture, maskTexture];
    textures.forEach(t => {
        t.vScale = -1;
    })

    var nodeMaterial = new BABYLON.NodeMaterial(name, scene);
// InputBlock
var position = new BABYLON.InputBlock("position");
position.setAsAttribute("position");

// TransformBlock
var WorldPos = new BABYLON.TransformBlock("WorldPos");
WorldPos.complementZ = 0;
WorldPos.complementW = 1;

// InstancesBlock
var Instances = new BABYLON.InstancesBlock("Instances");

// InputBlock
var world = new BABYLON.InputBlock("world0");
world.setAsAttribute("world0");

// InputBlock
var world1 = new BABYLON.InputBlock("world1");
world1.setAsAttribute("world1");

// InputBlock
var world2 = new BABYLON.InputBlock("world2");
world2.setAsAttribute("world2");

// InputBlock
var world3 = new BABYLON.InputBlock("world3");
world3.setAsAttribute("world3");

// InputBlock
var world4 = new BABYLON.InputBlock("world");
world4.setAsSystemValue(BABYLON.NodeMaterialSystemValues.World);

// TransformBlock
var WorldPosViewProjectionTransform = new BABYLON.TransformBlock("WorldPos * ViewProjectionTransform");
WorldPosViewProjectionTransform.complementZ = 0;
WorldPosViewProjectionTransform.complementW = 1;

// InputBlock
var ViewProjection = new BABYLON.InputBlock("ViewProjection");
ViewProjection.setAsSystemValue(BABYLON.NodeMaterialSystemValues.ViewProjection);

// VertexOutputBlock
var VertexOutput = new BABYLON.VertexOutputBlock("VertexOutput");

// InputBlock
var uv = new BABYLON.InputBlock("uv");
uv.setAsAttribute("uv");

// TextureBlock
var Diffuse = new BABYLON.TextureBlock("Diffuse");

// MultiplyBlock
var Multiply = new BABYLON.MultiplyBlock("Multiply");

// TextureBlock
var AmbientOcclusion = new BABYLON.TextureBlock("AmbientOcclusion");

// FragmentOutputBlock
var FragmentOutput = new BABYLON.FragmentOutputBlock("FragmentOutput");

// TextureBlock
var Mask = new BABYLON.TextureBlock("Mask");

// DiscardBlock
var Discard = new BABYLON.DiscardBlock("Discard");

// InputBlock
var Float = new BABYLON.InputBlock("Float");
Float.value = 0.5;
Float.min = 0;
Float.max = 0;
Float.isBoolean = false;
Float.matrixMode = 0;
Float.animationType = BABYLON.AnimatedInputBlockTypes.None;
Float.isConstant = false;
Float.visibleInInspector = false;

// FogBlock
var Fog = new BABYLON.FogBlock("Fog");

// Connections
position.output.connectTo(WorldPos.vector);
world.output.connectTo(Instances.world0);
world1.output.connectTo(Instances.world1);
world2.output.connectTo(Instances.world2);
world3.output.connectTo(Instances.world3);
world4.output.connectTo(Instances.world);
Instances.output.connectTo(WorldPos.transform);
WorldPos.output.connectTo(WorldPosViewProjectionTransform.vector);
ViewProjection.output.connectTo(WorldPosViewProjectionTransform.transform);
WorldPosViewProjectionTransform.output.connectTo(VertexOutput.vector);
uv.output.connectTo(AmbientOcclusion.uv);
AmbientOcclusion.rgb.connectTo(Multiply.left);
uv.output.connectTo(Diffuse.uv);
Diffuse.rgb.connectTo(Multiply.right);
Multiply.output.connectTo(Fog.input);
uv.output.connectTo(Mask.uv);
Mask.r.connectTo(Discard.value);
Float.output.connectTo(Discard.cutoff);
WorldPos.connectTo(Fog);
Fog.output.connectTo(FragmentOutput.rgb);

    Diffuse.texture = diffuseTexture;
    AmbientOcclusion.texture = aoTexture;
    Mask.texture = maskTexture;

    // Output nodes
    nodeMaterial.addOutputNode(VertexOutput);
    nodeMaterial.addOutputNode(FragmentOutput);
    nodeMaterial.addOutputNode(Discard);
    nodeMaterial.build();

    nodeMaterial.name = name;
    nodeMaterial.backFaceCulling = false;
    return nodeMaterial;
}