// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3xeKYbSnoQTLzkoDsQfkc4{background:#fff;flex-grow:1;overflow:hidden}._3xeKYbSnoQTLzkoDsQfkc4 canvas{outline:none;display:block;touch-action:none}._3xeKYbSnoQTLzkoDsQfkc4 ._3DXI8VuynZDnnAZSn_RAn_{margin:auto;position:relative}._3xeKYbSnoQTLzkoDsQfkc4 .KI-c9krsba95jV7EZF6sE{flex:1}", "",{"version":3,"sources":["webpack://./src/components/canvas/Canvas.module.scss"],"names":[],"mappings":"AAAA,yBACI,eAAA,CACA,WAAA,CACA,eAAA,CAEA,gCACI,YAAA,CACA,aAAA,CACA,iBAAA,CAGJ,kDACI,WAAA,CACA,iBAAA,CAGJ,gDACI,MAAA","sourcesContent":[".root {\n    background: white;\n    flex-grow: 1;\n    overflow: hidden;\n\n    canvas {\n        outline: none;\n        display: block;\n        touch-action: none;\n    }\n\n    .canvasContainer {\n        margin: auto;\n        position: relative;\n    }\n\n    .view {\n        flex: 1\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_3xeKYbSnoQTLzkoDsQfkc4",
	"canvasContainer": "_3DXI8VuynZDnnAZSn_RAn_",
	"view": "KI-c9krsba95jV7EZF6sE"
};
export default ___CSS_LOADER_EXPORT___;
