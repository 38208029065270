import "./styles.scss";
import "babylonjs-loaders"
import { saveAndReload, parseCurrentHash } from "./persistence";
import * as Hub from "./messaging/hub";
import * as Config from './loaders/config';
import { Loader } from '@googlemaps/js-api-loader';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';

async function renderApp() {
    await new Loader({
        apiKey: Config.googleMapApiKey,
        version: "weekly",
        libraries: ["places"]
    }).load();

    ReactDOM.render(<App />, document.getElementById("root"));
}

async function renderGoogleAnalytics() {
    const loadAnalyticsScript = document.createElement("script");
    loadAnalyticsScript.async = true;
    loadAnalyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=${Config.googleAnalyticsId}`;
    
    const initAnalyticsScript = document.createElement("script");
    initAnalyticsScript.innerHTML = `        window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${Config.googleAnalyticsId}');`;
    
    document.head.appendChild(loadAnalyticsScript);
    document.head.appendChild(initAnalyticsScript);
}

function renderHotjar() {
    const hotjarScript = document.createElement("script");
    hotjarScript.innerHTML = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${Config.hotjarId},hjsv:${Config.hotjarSv}};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    document.head.appendChild(hotjarScript);
}

async function setupSignalR() {
    try {
        let options = parseCurrentHash();
        if (!options.remoteControl)
            return;

        Hub.onSessionUpdated((lat, lng, yardName, brand) => {
            let options = parseCurrentHash();
            if (lat) options.location.lat = lat;
            if (lng) options.location.lng = lng;
            if (yardName) options.yardName = yardName;
            if (brand) options.brand = brand;
            saveAndReload(options);
        });

        Hub.onPing(id => {
            Hub.sendPong(id)
        });

        // See also: Canvas.tsx

        await Hub.connect();
    }
    catch (e) {
        console.log(e);
    }
}

Config.load().finally(async () => {
    await renderGoogleAnalytics();
    renderHotjar();
    await renderApp();
    await setupSignalR();
});
