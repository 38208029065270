import React from 'react';
import styles from './SplashScreen.module.scss';
import cn from 'classnames';

interface ISplashScreenProps {
    open: boolean;
    setClosed: () => void;
}

export const SplashScreen: React.FC<ISplashScreenProps> = (props) => {
    return (
        <div className={cn(styles.root, { [styles.closed]: !props.open })}>
            <img src={"www/images/2461929-colorized-cropped.png"} />
            <span className={styles.description}><em>Digitally explore</em> farm yard designs on <em>your property</em>.</span>
            <span className={styles.hint}>Whenever you are ready, we’ll help you design a solution that fits your business needs.</span>
            <button className={styles.getStartedButton} onClick={props.setClosed}>Let's get started</button>
        </div>
    )
}
