import React, { useContext, useState } from 'react';
import { GoogleMap } from './googleMap/GoogleMap';
import styles from './LocationPicker.module.scss';
import { Options } from '../../App';
import { saveAndReload } from '../../persistence';

interface ILocationPickerProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LocationPicker: React.FC<ILocationPickerProps> = (props) => {
    const [selectedCoordinates, setSelectedCoordinates] = useState<google.maps.LatLng | null>(null);

    const options = useContext(Options);

    function close() {
        props.setOpen(false);
    }

    function placeFarmYard() {
        close();
        if (selectedCoordinates) {
            options.farmSceneOptions.location = {
                lat: selectedCoordinates.lat(),
                lng: selectedCoordinates.lng(),
                zoom: options.farmSceneOptions.location.zoom
            };
            saveAndReload(options.farmSceneOptions);
        }
    }

    return (
        <div className={`${styles.root} ${props.open ? styles.open : styles.closed}`}>
            <span className={styles.prompt}>Where would you like us to <em>place your farm yard?</em></span>
            <GoogleMap open={props.open} onCoordinatesSelected={(coordinates) => setSelectedCoordinates(coordinates)} />
            <span className={styles.hint}><em><u>Hint:</u></em> Move the dropped pin for more precise control</span>
            <button className={styles.placeYardButton} onClick={placeFarmYard}>Place farm yard</button>
            <button className={styles.skipButton} onClick={close}>Skip for now</button>
        </div>
    )
}
