import React, { useContext, useState } from 'react';
import { ReactComponent as RotateIcon } from '../../icons/rotate-yard.svg';
import styles from './FloatingButtonsPanel.module.scss';
import cn from 'classnames';
import { Options } from '../../../App';
import useOnclickOutside from 'react-cool-onclickoutside';

interface IRotateYardButton {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RotateYardButton: React.FC<IRotateYardButton> = (props) => {
    const options = useContext(Options);
    const [open, setOpen] = useState<boolean | null>(null);
    const ref = useOnclickOutside(() => {
        open === true && setOpen(false);
    }, {
        disabled: !open
    });

    return (
        <button ref={ref} className={styles.rotateYardButton} onClick={() => setOpen(!open)} title="Rotate yard">
            <RotateIcon />
            <div onClick={(event) => event.stopPropagation()} className={cn(styles.rotateYardSlider, { [styles.open]: open === true, [styles.closed]: open === false })}>
                <input type="range" defaultValue={options.farmSceneOptions?.heading} min={0} max={360} step={1} onChange={props.handleChange}></input>
            </div>
        </button>
    );
}
