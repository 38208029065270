import { Scene, AbstractMesh, Vector3, DepthOfFieldMergePostProcessOptions, Ray, GroundMesh, Vector2 } from "babylonjs";
import { addTrees } from "./trees";
import { IRenderedTile } from "./tile";
import { tileCenter2coord, tilePosition2coord, lat2partialTile, long2partialTile, tile2lat, tile2long } from "../geo/geo";

export class RenderedWorld {
    public allTiles: IRenderedTile[];
    public allTrees: Promise<AbstractMesh>;

    private worldOffset: Vector3 = new Vector3(0,0,0);

    constructor(public center: IRenderedTile, public surrounding: IRenderedTile[]) {
        this.allTiles = [center].concat(...surrounding);
    }

    centerAround(origin: Vector3) {        
        let yOffset = this.queryHeight(origin.x, origin.z);
        let d = new Vector3(-origin.x, -yOffset, -origin.z);
        this.worldOffset = d.clone();

        // See https://www.babylonjs-playground.com/#1JXJVF#3
        this.allTiles.forEach(t => {
            t.groundMesh.updateMeshPositions((positions) => {
                for (var idx = 0; idx < positions.length; idx += 3) {
                    positions[idx+1] += d.y;
                }
            }, false)
            
            t.groundMesh.position.x += d.x;
            t.groundMesh.position.z += d.z;
            t.groundMesh.updateCoordinateHeights();
            t.groundMesh.refreshBoundingInfo();
        });
        return Vector3.Zero();
    }

    moveDown(originX: number, originZ: number) {
        let yOffset = this.queryHeight(originX, originZ);
        // See https://www.babylonjs-playground.com/#1JXJVF#3
        this.allTiles.forEach(t => {
            t.groundMesh.updateMeshPositions((positions) => {
                for (var idx = 1; idx < positions.length; idx += 3) {
                    positions[idx] -= yOffset;
                }
            }, false)
            
            t.groundMesh.refreshBoundingInfo();
            t.groundMesh.updateCoordinateHeights();
        });
    }

    queryHeight(worldX: number, worldZ: number): number {
        for (var i = 0; i < this.allTiles.length; i++) {
            let pos = this.allTiles[i].groundMesh.getHeightAtCoordinates(worldX, worldZ);
            
            if (pos != 0)
            {
                return pos;
            }
        }
        return 0;
    }

    addTrees(scene: Scene) {
        let treePromises: Promise<AbstractMesh[]>[] = [];

        this.allTiles.forEach(async (tile, i) => {            
            let trees = addTrees(scene, tile.groundMesh, tile, tile.imageryTexture, tile.terrainMapTexture, this.worldOffset);
            treePromises.push(trees);
        });

        return Promise.all(treePromises)
            .then(deepTrees => {
                return [].concat(...deepTrees) as AbstractMesh[];
            })
    }
    
    positionToLocation(scene: Scene, worldPos: Vector3) {
        let ray = new Ray(worldPos.add(new Vector3(0, 100, 0)), Vector3.Down());
        let hit = scene.pickWithRay(ray, m => this.allTiles.map(t => t.groundMesh).indexOf(m as GroundMesh) > -1);

        let tile = this.allTiles.find(t => hit.pickedMesh == t.groundMesh);

        let positionOnTile = new Vector2(
            hit.pickedPoint.x - hit.pickedMesh.absolutePosition.x,
            hit.pickedPoint.z - hit.pickedMesh.absolutePosition.z,
        );
        
        let normalizedTilePosition = new Vector2(
            (positionOnTile.x / tile.size + 0.5),
            1-(positionOnTile.y / tile.size  + 0.5)
        )

        let latLng = tilePosition2coord(tile.tile, normalizedTilePosition);
        
        // let start = parseHash().location;
        // console.log(latLng);

        // console.log(`Hit ${tile.tileOffset.x},${tile.tileOffset.y} at ${hit.pickedPoint.x},${hit.pickedPoint.z}`)
        // console.log(`  Visual Delta: 0.022,0.06`)
        // console.log(`  Tile: ${tile.tile.x},${tile.tile.y}`)
        // console.log(`  Locally: ${positionOnTile.x},${positionOnTile.y}`)
        // console.log(`  Normalized: ${normalizedTilePosition.x},${normalizedTilePosition.y}`)
        // console.log(`  LatLng: ${latLng.lat},${latLng.lng}`)
        // console.log(`  Start: ${start.lat},${start.lng}`)
        // console.log(`  Start Tile: ${long2partialTile(start.lng, start.zoom)},${lat2partialTile(start.lat, start.zoom)}`)
        // console.log(`  PartialTile: ${tile.tile.x+normalizedTilePosition.x},${tile.tile.y + normalizedTilePosition.y}`)


        // console.log(tile2lat(lat2partialTile(start.lat, 18), 18));
        // console.log(tile2long(long2partialTile(start.lng, 18), 18));

        // console.log("hit ", hit);
        // console.log("tile ", tile);

        // console.log("Location is")
        return latLng;
    }
}
