import React, { useEffect, useState } from 'react';
import { ILocation } from '../geo/geo';
import { imageryUrlCustom } from '../loaders';
import { ReactComponent as MapMarker } from './icons/MapMarker.svg';
import styles from './ChangeLocationButton.module.scss';
import cn from 'classnames';

interface IChangeLocationButton {
    handleClick: () => void;
    location: ILocation;
    isLocationPickerOpen: boolean;
    disabled: boolean;
}

export const ChangeLocationButton: React.FC<IChangeLocationButton> = (props) => {
    const [open, setOpen] = useState<boolean | null>(null);

    useEffect(() => {
        if (!props.isLocationPickerOpen && open === null) return;
        setOpen(props.isLocationPickerOpen);
    }, [props.isLocationPickerOpen]);

    const mapUrl = imageryUrlCustom(props.location.lat, props.location.lng, 16, "256x256");
    let backgroundStyle = {backgroundImage: `url(${mapUrl})`};

    return <button disabled={props.disabled} onClick={props.handleClick} className={cn(styles.container, { [styles.locationPickerOpen]: open, [styles.locationPickerClosed]: open === false })} style={backgroundStyle}>
        <div className={styles.gradient}></div>
        <MapMarker className={styles.marker} />
        <div className={styles.title}>Change Location</div>
    </button>;
}
