import { Scene, StandardMaterial, PBRMaterial, Color3, CubeTexture, Texture, Material } from "babylonjs";
import { reflectSkybox } from "../loaders/textures";

export function getOrCreateMaterial(materialName: string, scene: Scene, createMaterial: () => Material) {
    let existing = scene.materials.find(m => m.name == materialName);
    if (existing)
        return existing;

    let material = createMaterial();
    material.name = materialName;
    return material;
}

export function getOrCreateTexture(materialName: string, scene: Scene, createTexture: () => Texture) {
    let existing = scene.textures.find(m => m.name == materialName);
    if (existing)
        return existing as Texture;

    let texture = createTexture();
    texture.name = materialName;
    return texture;
}

export function pbrToStandard(scene: Scene, pbrMaterial: PBRMaterial) {
    let stdMaterial = new StandardMaterial(pbrMaterial.name, scene);

    stdMaterial.diffuseTexture = pbrMaterial.albedoTexture;
    stdMaterial.diffuseColor = pbrMaterial.albedoColor;

    stdMaterial.bumpTexture = pbrMaterial.bumpTexture;
    stdMaterial.roughness = pbrMaterial.roughness;
    stdMaterial.specularColor = Color3.Black();
    
    if (pbrMaterial.metallic > 0) 
    {
        let reflection = reflectSkybox(scene, 0.25);
        stdMaterial.reflectionTexture = reflection;
        stdMaterial.ambientColor = stdMaterial.diffuseColor.scale(1);
        stdMaterial.ambientTexture = stdMaterial.diffuseTexture;
        stdMaterial.diffuseColor = stdMaterial.diffuseColor.scale(0);
    }

    return stdMaterial;
}