import React from 'react';
import styles from './LoadingScreen.module.scss';
import { ReactComponent as TruckIcon } from '../icons/truck.svg';
import cn from 'classnames';

interface ILoadingScreenProps {
    open: boolean;
}

export const LoadingScreen: React.FC<ILoadingScreenProps> = (props) => {
    return (
        <div className={cn(styles.root, { [styles.open]: props.open })}>
            <TruckIcon className={styles.truck} />
            <span>On its way...</span>
        </div>
    );
}
