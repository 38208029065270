import React from 'react';
import { ReactComponent as VRIcon } from '../../icons/VR.svg';

interface IVRButtonProps {
    handleClick: () => void;
}

export const VRButton: React.FC<IVRButtonProps> = (props) => {
    return (
        <button onClick={props.handleClick}>
            <VRIcon />
        </button>
    );
}
