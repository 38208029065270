import { Scene, MeshBuilder, Vector3, Quaternion, TransformNode, StandardMaterial, Color3, SceneLoader, AbstractMesh, GroundBuilder, Mesh } from "babylonjs";
import { FarmYard, FarmObject, IVector3, IQuaternion, Building } from "./farmyard.model";
import { smoothMetalMaterial, bluePaint, yellowPaint, logoMaterial, rampMaterial, woodMaterial, grateMaterial, spangledMetalMaterial, metalcorpLogoMaterial, orrconSteelLogoMaterial, cattlemasterLogoMaterial, classicPlusLogoMaterial, rubberMaterial, hobbymasterLogoMaterial, concreteMaterial } from "../materials/productMaterials";
import { Quality } from "../farmScene/farmScene";
import { IGlbModelAdjustment, ensureProductCatalogLoaded, getModelPathForProduct, getProductByBrandAndTemplate, getProductByDrawingNumber } from "./catalog";

// For some reason, the numbers in the yard layout or all smaller by
// this factor. EG. a 2.5m fence reports scale=2.11
export const weirdScalingFactor = 0.853;

export async function renderFarmYard(scene: Scene, farmyard: FarmYard, quality: Quality, brand: string) {
    const parent = new TransformNode(farmyard.fileName, scene);

    let ground = GroundBuilder.CreateGround("Alignment Slab", { width: 50, height: 50 }, scene);
    ground.overlayColor = Color3.Red();
    ground.renderOverlay = true;
    ground.setEnabled(false);

    var objects = farmyard.farmObjectDictionary.m_values;

    let baseMeshes = await createBaseMeshes(scene, objects, parent, quality, brand, farmyard.stockType, farmyard.isCustomYard);
    createClonedMeshes(objects, baseMeshes, parent);

    let buildings = farmyard.buildings;

    if(!!buildings) await createScalableSheds(scene, buildings.scalableSheds, parent, quality);
    
    parent.getChildMeshes().forEach((mesh) => mesh.isVisible = true);

    let centerX = objects.length > 0 ? objects.map(o => o.position.x).reduce((a, b) => a + b) / objects.length : 0;
    let centerZ = objects.length > 0 ? objects.map(o => o.position.y).reduce((a, b) => a + b) / objects.length : 0;

    let anchor = addAnchor(parent, scene);

    anchor.position.x = -centerX;
    anchor.position.z = -centerZ;

    return parent;
}

async function createBaseMeshes(scene: Scene, objects: FarmObject[], parent: TransformNode, quality: Quality, brand: string, stockType: string, isCustomYard: boolean): Promise<AbstractMesh[]> {
    let firstInstancesOfEachFarmObject = objects.filter((obj, i) => objects.findIndex((o) => o.drawingNumber
        ? o.drawingNumber === obj.drawingNumber
        : o._templateName === obj._templateName) === i);

    await ensureProductCatalogLoaded();

    let promises: Promise<AbstractMesh>[] = firstInstancesOfEachFarmObject.map(async (obj) => {
        let mesh = null;
        const name = obj.drawingNumber || obj._templateName;

        const product = !!brand ?
            getProductByBrandAndTemplate(brand, obj._templateName, stockType) :
            getProductByDrawingNumber(obj.drawingNumber, stockType);

        // If the drawing numbers match then we can try to get the specified revision
        // Otherwise we may have switched brands and the revisions don't necessarily align, so just get the latest
        const revision = isCustomYard && product?.DrawingNumber === obj.drawingNumber ?
            product?.Revisions.find(r => r.RevisionID === (obj.revision || 0)) :
            product?.Revisions[product.Revisions.length - 1];

        const glbModelAdjustments = revision?.GlbModelAdjustments;

        let modelPath = getModelPathForProduct(product, revision?.RevisionID || 0);

        mesh = !!modelPath
            ? await createMesh(name, modelPath, scene, obj, parent, quality, glbModelAdjustments)
            : createPlaceholder(name, scene, obj, parent);

        [mesh, ...mesh.getChildMeshes()].forEach((subMesh) => subMesh.isVisible = false);
        mesh.cullingStrategy = Mesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;
        return mesh;
    });

    return await Promise.all(promises);
}

async function createScalableSheds(scene: Scene, sheds: Building[], parent: TransformNode, quality: Quality) {
    if(!sheds || sheds.length < 1) return;

    const name = "shed";

    let cloneSource: Mesh;

    for(let i = 0; i < sheds.length; i++)
    {
        let baseBuildingRoot: Mesh;
        
        if(i === 0)
            {
            baseBuildingRoot = new Mesh(name, scene);
            let baseBuilding = MeshBuilder.CreateBox(name, {}, scene);

            baseBuilding.parent = baseBuildingRoot;
            baseBuilding.isPickable = false;

            cloneSource = baseBuildingRoot;
        } else {
            baseBuildingRoot = cloneSource.clone(name, parent);
            baseBuildingRoot.cullingStrategy = Mesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;
        }
        
        let rot = quaternion(sheds[i].rotation).toEulerAngles();
        let pos = new Vector3(sheds[i].position.x, 0, sheds[i].position.y);
    
        baseBuildingRoot.rotationQuaternion = Quaternion.Identity();
        baseBuildingRoot.addRotation(0, -rot.z, 0)
        baseBuildingRoot.position = pos;
        baseBuildingRoot.position.y = (sheds[i].scale.z / 2);
        baseBuildingRoot.scaling = vector3({
            x: sheds[i].scale.x,
            y: sheds[i].scale.z,
            z: sheds[i].scale.y
        });

        baseBuildingRoot.material = smoothMetalMaterial(scene, quality);
        baseBuildingRoot.parent = parent;
    }
}

function createClonedMeshes(objects: FarmObject[], baseMeshes: AbstractMesh[], parent: TransformNode) {
    let subsequentInstancesOfEachFarmObject = objects.filter((obj, i) => objects.findIndex((o) => obj.drawingNumber
        ? o.drawingNumber === obj.drawingNumber
        : o._templateName === obj._templateName) < i);

    subsequentInstancesOfEachFarmObject.forEach((obj, i) => {
        let name = `${obj.drawingNumber || obj._templateName}_${i}`;
        let mesh = baseMeshes.find((m) => m.name === obj.drawingNumber || m.name === obj._templateName);
        if (mesh) {
            let clone = mesh.clone(name, parent);
            setupMesh(name, [clone], obj, parent);
            clone.cullingStrategy = Mesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION;
        }
    });
}

async function createMesh(name: string, fileName: string, scene: Scene, obj: FarmObject, parent: TransformNode, quality: Quality, glbModelAdjustments: IGlbModelAdjustment) {
    let result = await SceneLoader.ImportMeshAsync(null, "catalog/", fileName, scene);

    result.meshes.forEach(mesh => {
        mesh.isPickable = false;
        mesh.metadata = { glbModelAdjustments: glbModelAdjustments};

        if(mesh instanceof Mesh)
        {
            let oldMaterial = mesh.material;
            if (mesh.material && mesh.material.name == "blue-industrial") {
                mesh.material = bluePaint(scene, quality);
            } else if (mesh.material && mesh.material.name == "yellow-industrial") {
                mesh.material = yellowPaint(scene, quality);
            } else if (mesh.material && mesh.material.name == "crush-logos") {
                mesh.material = logoMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name == "grate") {
                mesh.material = grateMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name.indexOf("rubber") > -1) {
                mesh.material = rubberMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name.indexOf("concrete") > -1) {
                mesh.material = concreteMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name == "spangled") {
                mesh.material = spangledMetalMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name == "ramp") {
                mesh.material = rampMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name.indexOf("timber") > -1) {
                mesh.material = woodMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name.indexOf("metalcorp-logo") > -1) {
                mesh.material = metalcorpLogoMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name.indexOf("orrcon-steel-logo") > -1) {
                mesh.material = orrconSteelLogoMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name.indexOf("cattlemaster-logo") > -1) {
                mesh.material = cattlemasterLogoMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name.indexOf("classic-plus-logo") > -1) {
                mesh.material = classicPlusLogoMaterial(scene, quality);
            } else if (mesh.material && mesh.material.name.indexOf("hobbymaster-logo") > -1) {
                mesh.material = hobbymasterLogoMaterial(scene, quality);
            } else {
                mesh.material = smoothMetalMaterial(scene, quality);
            }
            if (oldMaterial) oldMaterial.dispose();
        }
    });

    let root = setupMesh(name, result.meshes, obj, parent);
    return root;
}

function setupMesh(name: string, meshes: AbstractMesh[], obj: FarmObject, parent: TransformNode) {
    var root = meshes[0];
    root.name = name;
    root.parent = parent;

    applyTransform(obj, root);
    fixMeshRotation(root);
    fixMeshPosition(root);
    fixMeshScaling(root);
    return root;
}

function addAnchor(root: TransformNode, scene: Scene) {
    let anchor = new TransformNode("Anchor", scene);
    root.getChildTransformNodes(true).forEach(m => m.parent = anchor);
    anchor.parent = root;
    return anchor;
}

async function applyTransform(obj: FarmObject, root: AbstractMesh) {
    let rot = quaternion(obj.rotation).toEulerAngles();
    let pos = vector3(obj.position).scale(1 / weirdScalingFactor);

    pos = new Vector3(pos.x, pos.z, pos.y);
    root.rotationQuaternion = Quaternion.Identity();
    root.addRotation(0, -rot.z, 0)
    root.position = pos;
    root.position.y = 0;
}

function fixMeshRotation(mesh: AbstractMesh) {
    if(!!mesh.metadata?.glbModelAdjustments) {
        mesh.rotate(Vector3.Up(), Math.PI * mesh.metadata.glbModelAdjustments.Rotation / 180);
    }
}

function fixMeshPosition(mesh: AbstractMesh) {
    if(!!mesh.metadata?.glbModelAdjustments) {
        mesh.translate(Vector3.Right(), mesh.metadata.glbModelAdjustments.Position.x);
        mesh.translate(Vector3.Forward(), mesh.metadata.glbModelAdjustments.Position.y);
    }
}

function fixMeshScaling(mesh: AbstractMesh) {
    if(!!mesh.metadata?.glbModelAdjustments) {
        mesh.scaling.scaleInPlace(mesh.metadata.glbModelAdjustments.Scale);
    }
}

function swizzle(input: Vector3) {
    return new Vector3(input.x, input.z, input.y);
}

function swizzleRot(input: Vector3) {
    return new Vector3(input.x, -input.z, input.y);
}

function createPlaceholder(name: string, scene: Scene, obj: FarmObject, parent: TransformNode) {
    // Something was going wrong with the cloning here, but changing this to Mesh and returning that seems to fix the issue. It may have somehing
    // to do with https://github.com/BabylonJS/Babylon.js/blob/a5f91d7554c1ce9429f6a7ba5ea12d6058377a43/src/Meshes/abstractMesh.ts#L1677 where
    // AbstractMesh.Clone() simply returns null. Keeping the return type as TransformNode won't work as other parts of the code need to call methods
    // relating to bounding info that only exist on AbstractMesh or Mesh types
    let placeholderRoot = new Mesh(name, scene);
    let cube = MeshBuilder.CreateBox(name, {
        size: 1
    }, scene);

    let placeholderMat = new StandardMaterial("Placeholder", scene);
    placeholderMat.alpha = 0.3;
    placeholderMat.backFaceCulling = false;
    cube.material = placeholderMat;

    cube.position.y = 0.25;
    cube.parent = placeholderRoot;
    cube.isPickable = false;
    placeholderRoot.position = swizzle(vector3(obj.position)).scale(1 / weirdScalingFactor)
    placeholderRoot.rotation = swizzleRot(quaternion(obj.rotation).toEulerAngles())
    placeholderRoot.scaling = swizzle(vector3(obj.scale)).scale(1 / weirdScalingFactor)
    placeholderRoot.scaling.y = 1.8;
    placeholderRoot.parent = parent;
    return placeholderRoot;
}

function vector3(input: IVector3) {
    return new Vector3(input.x, input.y, input.z)
}

function quaternion(input: IQuaternion) {
    return new Quaternion(input.x, input.y, input.z, input.w)
}