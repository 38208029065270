import { AbstractMesh, ActionManager, Color3, DirectionalLight, Engine, HemisphericLight, Mesh, PickingInfo, Quaternion, RenderTargetTexture, Scene, ShadowGenerator, Texture, TransformNode, Vector3 } from "babylonjs";
import { displacement, IMapScene, tileCenter2coord, tileSizeMetres } from "../geo/geo";
import { renderFarmYard } from "../loaders/farmyard";
import { loadFarmYard } from "../loaders/farmyard.model";
import { skyboxCubeTexture } from "../loaders/textures";
import { RenderedWorld } from "../meshes/RenderedWorld";
import { createLevel } from "../meshes/tile";
import { flattenGroundAroundNode, slopeWithGround } from "../utils";
import { CustomXRExperience } from "./customXRExperience";
import { FirstPersonCamera } from "./firstPersonCamera";
import { getBrandOrDefaultForStockType } from "../loaders/catalog";

export type Quality = "Standard" | "High";
export const allQualities: Quality[] = ["Standard", "High"];

export interface IFarmSceneOptions {
    location: IMapScene;
    yardName: string;
    quality: Quality;
    treesCastShadows: boolean;
    yardCastsShadows: boolean;
    livestockEnabled: boolean;
    remoteControl: boolean;
    brand: string;
    heading: number;
}

export class FarmScene {
    public scene: Scene;
    public canvas: HTMLCanvasElement;
    public level: RenderedWorld;
    public yard: TransformNode;
    private xr: CustomXRExperience;

    private engine: Engine;

    private options: IFarmSceneOptions;
    private farPlane = 1e6;

    private firstPersonCamera: FirstPersonCamera;

    private mainLight: DirectionalLight;
    private yardShadowGenerator: ShadowGenerator;

    private treeShadowLight: DirectionalLight;
    private treeShadowGenerator: ShadowGenerator;

    private cows: AbstractMesh[];
    private trees: AbstractMesh[];

    public static async CreateNewFarmSceneAsync(engine: Engine, canvas: HTMLCanvasElement, options: IFarmSceneOptions): Promise<FarmScene> {
        let farmScene = new FarmScene(engine, canvas, options);

        await farmScene.reloadWorld();
        await farmScene.createXRExperience();
        farmScene.firstPersonCamera.resetPosition();

        window.addEventListener("keypress", showDebugLayer);
        farmScene.scene.onDisposeObservable.add(() => window.removeEventListener("keypress", showDebugLayer));
        function showDebugLayer(ev: KeyboardEvent) {
            if (ev.keyCode == 73) // SHIFT + I
                farmScene.scene.debugLayer.show();
        }

        return farmScene;
    }

    private constructor(engine: Engine, canvas: HTMLCanvasElement, options: IFarmSceneOptions) {
        this.engine = engine;

        this.scene = new Scene(engine, {
            useGeometryUniqueIdsMap: true,
            useMaterialMeshMap: true,
            useClonedMeshMap: true
        });
        this.scene.preventDefaultOnPointerDown = false;
        this.scene.autoClear = false;
        this.scene.autoClearDepthAndStencil = false;
        this.scene.actionManager = new ActionManager(this.scene);

        this.canvas = canvas;
        this.updateOptions(options);

        this.createLights();
        this.createDefaultEnvironment();
        this.createYardShadowGenerator();
        this.createTreeShadowGenerator();
        this.createFirstPersonCamera();

        engine.runRenderLoop(() => {
            this.scene.render();
        });
    }

    public updateOptions(options: IFarmSceneOptions) {
        this.options = {
            location: { ...options.location },
            yardName: options.yardName,
            quality: options.quality,
            treesCastShadows: options.treesCastShadows,
            yardCastsShadows: options.yardCastsShadows,
            livestockEnabled: options.livestockEnabled,
            remoteControl: options.remoteControl,
            brand: options.brand,
            heading: options.heading
        }

        if (this.engine.webGLVersion == 1) {
            // WebGL 1 tree shadows look bad
            this.options.treesCastShadows = false;
        }
    }

    public async loadNewFarmyard() {
        this.disposeOfExistingYard();
        this.recreateYardShadowMap();

        await this.loadFarmyard();
        this.recreateYardShadowMap();
        this.firstPersonCamera?.resetPosition();
    }

    public async reloadWorld() {
        this.scene.blockMaterialDirtyMechanism = true;
        this.yard && this.firstPersonCamera?.resetPosition();
        this.disposeOfExistingYard();
        this.disposeOfExistingWorld();
        await this.createLevel();
        await this.loadNewFarmyard();
        await this.spawnTrees();
        this.recreateTreeShadowMap();
        this.setSceneFog();
        this.scene.blockMaterialDirtyMechanism = false;
    }

    public resetPosition() {
        let [position, rotation] = this.firstPersonCamera?.resetPosition();
        this.xr?.resetCamera(position, rotation);
    }

    public get xrSupported() {
        return !!this.xr;
    }

    public enterXRSession() {
        this.xr.enterXRAsync();
    }

    public toggleDeviceOrientationInput() {
        this.firstPersonCamera.toggleDeviceOrientationInput();
    }

    public rotateYardByDegrees(degrees: number) {
        this.yard.rotate(Vector3.Up(), Math.PI / 180 * degrees);
        this.recreateYardShadowMap();
    }

    private disposeOfExistingYard() {
        this.scene.blockfreeActiveMeshesAndRenderingGroups = true;
        this.yard?.dispose();
        this.cows?.forEach((c) => c.dispose());
        this.scene.getNodes().forEach((n) => {
            if (n.name.startsWith("Alignment Slab")) n.dispose();
        });
        this.scene.blockfreeActiveMeshesAndRenderingGroups = false;
    }

    private disposeOfExistingWorld() {
        this.scene.blockfreeActiveMeshesAndRenderingGroups = true;
        this.trees?.forEach((t) => t.dispose());
        this.scene.getNodes().forEach((n) => {
            if (n.name.startsWith("Ground_")) n.dispose(false, true);
        });
        this.scene.textures.forEach((t) => {
            if (t.name.startsWith("http")) t.dispose();
            if (t.name.startsWith("Textures/DefaultLocation")) t.dispose();
            if (t.name === "Satellite Texture") t.dispose();
        });
        this.scene.blockfreeActiveMeshesAndRenderingGroups = false;
    }

    private createLights() {
        this.mainLight = new DirectionalLight("MainLight", new Vector3(-1, -1, 1), this.scene);
        this.mainLight.position = new Vector3(50, 80, -20);
        this.mainLight.intensity = 0.5;
        this.mainLight.shadowMinZ = 50;
        this.mainLight.shadowMaxZ = 200;

        const hemisphericLight = new HemisphericLight("FillLight", this.mainLight.direction.clone(), this.scene);
        hemisphericLight.intensity = 0.2;

        this.treeShadowLight = this.mainLight.clone("TreeLight") as DirectionalLight;
        this.treeShadowLight.direction = new Vector3(0.1, -1, 0.1);
        this.treeShadowLight.intensity = 0.8;
    }

    private createDefaultEnvironment() {
        let env = this.scene.createDefaultEnvironment({
            skyboxColor: Color3.White(),
            skyboxSize: this.farPlane * 0.8,
            skyboxTexture: skyboxCubeTexture(this.scene).clone(),
            groundYBias: 1
        });

        env.skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
        env.skyboxMaterial.reflectionTexture.wrapU = 0;
        env.skyboxMaterial.reflectionTexture.wrapV = 0;
        env.skybox.applyFog = false;

        this.scene.environmentIntensity = 0.5;
        this.scene.ambientColor = new Color3(0.3, 0.3, 0.3);
        this.scene.meshes.find(m => m.name === "BackgroundPlane").dispose();
        this.scene.collisionsEnabled = true;
    }

    private createYardShadowGenerator() {
        if (!this.options.yardCastsShadows)
            return;
        this.yardShadowGenerator = new ShadowGenerator(2048, this.mainLight);
        this.yardShadowGenerator.usePoissonSampling = true;
        this.yardShadowGenerator.usePercentageCloserFiltering = true;
        this.yardShadowGenerator.filteringQuality = ShadowGenerator.QUALITY_MEDIUM;
        this.yardShadowGenerator.bias = 0.005;
        this.yardShadowGenerator.normalBias = 0.01;
        this.yardShadowGenerator.forceBackFacesOnly = true;
    }

    private createTreeShadowGenerator() {
        if (this.options.treesCastShadows) {
            this.treeShadowGenerator = new ShadowGenerator(512, this.treeShadowLight);
            this.treeShadowGenerator.usePercentageCloserFiltering = true;
        }

        if (this.options.yardCastsShadows) {
            this.yardShadowGenerator.filteringQuality = ShadowGenerator.QUALITY_LOW;
        }
    }

    private createFirstPersonCamera() {
        this.firstPersonCamera = new FirstPersonCamera(this, {
            viewDistance: this.farPlane,
            minimumDistanceFromGround: 0.3
        });
    }

    private moveCameraOnDoubleClick(pickingInfo: PickingInfo) {
        this.firstPersonCamera.moveTo(pickingInfo.pickedPoint, 2.5, 20, 0.8);
    }

    private async createLevel() {
        this.level = await createLevel(this.scene, this.options.location, (info) => this.moveCameraOnDoubleClick(info));
        let center = this.level.center;
        let originalOrigin = displacement(tileCenter2coord(center.tile), this.options.location, this.options.location.zoom);
        this.level.centerAround(originalOrigin);
    }

    private async loadFarmyard() {
        let farmyard = await loadFarmYard(this.options.yardName);
        this.yard = await renderFarmYard(this.scene, farmyard, this.options.quality, getBrandOrDefaultForStockType(this.options.brand || farmyard.brand, farmyard.stockType));
        this.yard.rotationQuaternion = Quaternion.FromEulerAngles(0, Math.PI / 180 * this.options.heading, 0);

        if (this.options.livestockEnabled) await this.spawnCows();

        this.yard.computeWorldMatrix();
        (this.yard.getChildMeshes(false)).forEach(n => n.computeWorldMatrix());
        slopeWithGround(this.yard, this.level);
        flattenGroundAroundNode(this.yard, this.level);

        if (this.options.yardCastsShadows) {
            var meshes = this.yard.getChildMeshes().filter(m => m instanceof Mesh);
            meshes.forEach(m => this.yardShadowGenerator.addShadowCaster(m));
            meshes.forEach(m => m.receiveShadows = true);
        }
    }

    private async spawnCows() {
        const cowLoader = await import("../loaders/cow");
        this.cows = await cowLoader.loadCow(this.scene, this.level, this.yard, this.options.quality);
        if (this.options.yardCastsShadows)
            this.cows.forEach(cow => this.yardShadowGenerator.addShadowCaster(cow));
    }

    private async spawnTrees() {
        this.trees = await this.level.addTrees(this.scene);

        if (this.options.treesCastShadows) {
            this.trees.forEach(tree => this.treeShadowGenerator.addShadowCaster(tree));
        }
    }

    private setSceneFog() {
        let minimumTileSizeForFog = 400;
        let cappedTileSizeInMetres = Math.max(tileSizeMetres(this.options.location, this.options.location.zoom) * 1.25, minimumTileSizeForFog);
        this.scene.fogMode = Scene.FOGMODE_LINEAR;
        this.scene.fogColor = new Color3(0.45, 0.525, 0.575);
        this.scene.fogStart = cappedTileSizeInMetres;
        this.scene.fogEnd = cappedTileSizeInMetres * 2.5;
    }

    private async createXRExperience() {
        this.xr = await CustomXRExperience.createCustomXRExperienceAsync(this, {
            viewDistance: this.farPlane,
            thumbstickThreshold: 0.05,
            stepDistance: 0.7,
            continuousMotionSpeed: 0.1,
            ambientSoundVolume: 0.2
        });
    }

    private recreateYardShadowMap() {
        if (!this.options.yardCastsShadows)
            return;
        this.mainLight.autoUpdateExtends = true;
        this.yardShadowGenerator.getShadowMap().refreshRate = RenderTargetTexture.REFRESHRATE_RENDER_ONCE;
        this.yardShadowGenerator.onAfterShadowMapRenderObservable.addOnce(() => {
            this.mainLight.autoUpdateExtends = false;
        });
    }

    private recreateTreeShadowMap() {
        if (!this.options.treesCastShadows)
            return;
        this.treeShadowLight.autoUpdateExtends = true;
        this.treeShadowLight.autoCalcShadowZBounds = true;
        this.treeShadowGenerator.getShadowMap().refreshRate = RenderTargetTexture.REFRESHRATE_RENDER_ONCE;
        this.treeShadowGenerator.onAfterShadowMapRenderObservable.addOnce(() => {
            this.treeShadowLight.autoUpdateExtends = false;
            this.treeShadowLight.autoCalcShadowZBounds = true;
        });
    }
}
