import { Scene } from "babylonjs/scene";
import { GroundMesh, SceneLoader, Vector3, Texture, Mesh, TransformNode, PBRMaterial, Color4, AbstractMesh } from "babylonjs";
import { ITileDefinition } from "./tile";
import seedRandom from "seedrandom";
import { createTreeMaterial } from "../materials/TreeMaterial";

let rng = seedRandom("MySeed");

interface TreeDefinition {
    name: string,
    getScaleFactor: (v: number) => number,
    mesh: Mesh;
}

let treeDefinitions: Promise<TreeDefinition>[];
let treeIndex = 0;
const treesPerHectare = 600;
const maxTreesPerTile = 600;
const distanceFromOrigin = 50;

let renderInstanced = true;
const renderFromBabylon = false;

class QueryableTexture {
    buffer: ArrayBufferView;
    width: number;
    height: number;
    constructor(texture: Texture) {
        this.buffer = texture.readPixels();
        let { width, height } = texture.getSize();
        this.width = width;
        this.height = height;
    }

    getColor(x: number, y: number): Color4 {
        let xi = Math.floor(x * this.width);
        let yi = Math.floor(y * this.height);

        let pos = xi + yi * this.width;
        pos = Math.floor(pos);

        let r = this.buffer[pos * 4];
        let g = this.buffer[pos * 4 + 1];
        let b = this.buffer[pos * 4 + 2];
        let a = this.buffer[pos * 4 + 3];

        return new Color4(r, g, b, a);
    }
}


export async function addTrees(scene: Scene, ground: GroundMesh, tileDef: ITileDefinition, imagery: Texture, terrainMap: Texture, positionOffset: Vector3): Promise<AbstractMesh[]> {

    let start = performance.now();

    let treesPerTile = treesPerHectare / Math.pow(2, tileDef.tile.zoom - 16);
    treesPerTile = Math.min(maxTreesPerTile, treesPerTile)

    let version = scene.getEngine().webGLVersion;
    if (version == 1) {
        // WebGL1 can't do instanced rendering
        renderInstanced = false;
        treesPerTile = Math.min(100, treesPerTile);
    }

    let imageryTexture = new QueryableTexture(imagery);
    let terrainMapTexture = new QueryableTexture(terrainMap);

    function rndPos() {
        return rng.quick() * tileDef.size - tileDef.size / 2;
    }

    if (!treeDefinitions) {
        treeDefinitions = [];

        let ghostGum = loadTree("GhostGum", scene)
            .then(mesh => {
                return {
                    name: "GhostGum",
                    getScaleFactor: v => (0.8 + v),
                    mesh: mesh
                };
            });

        let crebra = loadTree("Crebra", scene)
            .then(mesh => {
                return {
                    name: "Crebra",
                    getScaleFactor: v => (1.5 + v),
                    mesh: mesh
                };
            });

        treeDefinitions.push(ghostGum);
        treeDefinitions.push(crebra);
        treeDefinitions.push(crebra);
        treeDefinitions.push(crebra);

        scene.onDisposeObservable.add(() => treeDefinitions = null);
    }

    let trees: AbstractMesh[] = [];
    let treeParent = renderInstanced ? null : new TransformNode("Tree Parent", scene);
    for (var i = 0; i < treesPerTile; i++) {

        if (i % 100 == 0) {
            start = performance.now();
        }

        let x = rndPos();
        let z = rndPos();
        let dx = tileDef.offsetFromCenterTile.x + positionOffset.x;
        let dz = tileDef.offsetFromCenterTile.z + positionOffset.z;
        let treeX = x + dx;
        let treeZ = z + dz;

        let treePosition = new Vector3(treeX, 0, treeZ);
        if (treePosition.length() < distanceFromOrigin)
            continue;

        let tx = (x + tileDef.size / 2) / tileDef.size;
        let ty = (z + tileDef.size / 2) / tileDef.size;

        let imageryColor = imageryTexture.getColor(tx, ty);
        let terrainMapColor = terrainMapTexture.getColor(tx, ty);

        let isGreenish = imageryColor.g > imageryColor.r * 1.1;
        let isGround = terrainMapColor.r > terrainMapColor.b && terrainMapColor.r > terrainMapColor.g;

        if (!isGreenish || !isGround) {
            continue;
        }

        let meshName = `Tree_${tileDef.tile.x}_${tileDef.tile.y}_${i}`;

        let queryX = x + dx;
        let queryZ = z + dz;

        let treeDefinition = await treeDefinitions[trees.length % treeDefinitions.length];
        let scale = treeDefinition.getScaleFactor(rng.quick());
        treePosition.y = ground.getHeightAtCoordinates(queryX, queryZ);

        let treeScaling = Vector3.One().scale(scale);
        let treeRotation = new Vector3(0, rng.quick() * 2 * Math.PI, 0);

        // GLB does a scale to get around LHS/RHS issues
        if (renderInstanced && !renderFromBabylon) {
            treePosition = treePosition.clone()
            treePosition.x *= -1;
        }
        if (renderFromBabylon) {
            treeScaling = treeScaling.scale(2.5);
        }

        let tree: AbstractMesh;
        if (renderInstanced) {
            tree = treeDefinition.mesh.createInstance(meshName);
            tree.parent = treeDefinition.mesh;
        } else {
            tree = treeDefinition.mesh.clone(meshName, treeParent);
        }
        tree.setEnabled(true);
        tree.isVisible = true;
        tree.isPickable = false;

        tree.position = treePosition;
        tree.scaling = treeScaling;

        if (renderFromBabylon) {
            tree.addRotation(treeRotation.x, treeRotation.y, treeRotation.z);
            tree.addRotation(-Math.PI / 2, 0, 0);
        } else {
            tree.rotation = treeRotation;
        }

        tree.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_BOUNDINGSPHERE_ONLY;
        tree.freezeWorldMatrix();
        trees.push(tree);
        treeIndex = (treeIndex + 1) % treeDefinitions.length
    }

    // console.log("Made " + trees.length + " trees in " + (performance.now() - start) + "ms");
    return trees;
}


function loadTree(treeName: string, scene: Scene) {
    return SceneLoader.ImportMeshAsync(null, "./Models/", `${treeName}.glb`)
        .then(result => {
            result.meshes.filter(m => m.material).forEach(mesh => {
                let oldMaterial = mesh.material as PBRMaterial;

                let leavesMaterial = createTreeMaterial(treeName, scene);
                leavesMaterial.backFaceCulling = false;

                oldMaterial.dispose();
                mesh.material = leavesMaterial;
                mesh.receiveShadows = false;
            });

            result.meshes.forEach(m => m.isVisible = false);
            let mesh = result.meshes.slice(1) as Mesh[];
            return mesh[0];
        });
}