import { Vector3, Vector2 } from "babylonjs";

export interface IMapScene extends ILocation{
    zoom: number
}

export interface ILocation {
    lat: number
    lng: number
}

export interface ITile {
    zoom: number
    x: number
    y: number
}

export interface IDefaultMapScene extends IMapScene {
    defaultYardName: string;
}

export const defaultLocationMapScene: IDefaultMapScene = {
    lat: -30.564528,
    lng: 150.624389,
    zoom: 16,
    defaultYardName: "15 Working Capacity"
}

export function tileCenter2coord(tile: ITile): ILocation {
    return {
        lat: tile2lat(tile.y + 0.5, tile.zoom),
        lng: tile2long(tile.x + 0.5, tile.zoom)
    }
}

export function tilePosition2coord(tile: ITile, pos: Vector2): ILocation {
    return {
        lat: tile2lat(tile.y + pos.y, tile.zoom),
        lng: tile2long(tile.x + pos.x, tile.zoom)
    }
}

export function coord2tile(zoom: number, location: ILocation): ITile {
    return {
        zoom,
        x: long2tile(location.lng, zoom),
        y: lat2tile(location.lat, zoom),
    }
}

export function isValidLocation(location: ILocation) {
    return location.lat !== null && location.lng !== null && Math.abs(location.lat) <= 90 && Math.abs(location.lng) <= 180;
}

const equatorialEarthCircumference = 40075016.686;
const equatorialEarthRadius = 6371000;

export function displacement(b: ILocation, a: ILocation, zoom: number): Vector3 {
    let alat = a.lat * Math.PI / 180;
    let alng = a.lng * Math.PI / 180;
    let blat = b.lat * Math.PI / 180;
    let blng = b.lng * Math.PI / 180;

    const x = (alng-blng) * Math.cos((alat+blat)/2);
    const z = (alat-blat);
    return new Vector3(x*equatorialEarthRadius, 0, z*equatorialEarthRadius);
}

export function tileSizeMetres(location: ILocation, zoom: number) {
    let lat = location.lat * Math.PI / 180;
    let sizeMetres = equatorialEarthCircumference * Math.cos(lat) / Math.pow(2, zoom);
    sizeMetres = Math.abs(sizeMetres);
    return sizeMetres;
}

export function long2tile(lon, zoom) {
    return (Math.floor(long2partialTile(lon, zoom)));
}
export function long2partialTile(lon, zoom) {
    return (((lon + 180) / 360 * Math.pow(2, zoom)));
}

export function lat2tile(lat, zoom) {
    return (Math.floor(lat2partialTile(lat, zoom)));
}
export function lat2partialTile(lat, zoom) {
    return (((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)));
}

export function tile2long(x, zoom) {
    return (x / Math.pow(2, zoom) * 360 - 180);
}

export function tile2lat(y, zoom) {
    var n = Math.PI - 2 * Math.PI * y / Math.pow(2, zoom);
    return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
}