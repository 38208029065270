import React, { createContext, useEffect, useState } from 'react';
import styles from './App.module.scss';
import { Attribution } from './components/attribution/Attribution';
import { Canvas } from './components/canvas/Canvas';
import { ChangeLocationButton } from './components/ChangeLocationButton';
import { Header } from './components/header/Header';
import { LeadForm } from './components/leadForm/LeadForm';
import { LoadingScreen } from './components/loadingScreen/LoadingScreen';
import { LocationPicker } from './components/locationPicker/LocationPicker';
import { OnboardingInstructions } from './components/onboardingInstructions/OnboardingInstructions';
import { Overlay } from './components/overlay/Overlay';
import { SplashScreen } from './components/splashScreen/SplashScreen';
import { Toolbar } from './components/toolbar/Toolbar';
import { IFarmSceneOptions } from './farmScene/farmScene';
import { parseCurrentHash } from './persistence';
import cn from 'classnames';
import { Help } from './components/help/Help';

export const Options = createContext<IOptions>(undefined);

export interface IVisibleButtons {
    fullScreen: boolean;
    immersiveMode: boolean;
    vr: boolean;
}

export interface IOptions {
    farmSceneOptions: IFarmSceneOptions;
    setChangeLocationHidden: React.Dispatch<React.SetStateAction<boolean>>;
    visibleButtons: IVisibleButtons;
    setVisibleButtons: (updatedButtons: IVisibleButtons) => void;
}

type OverlayState = "default" | "loading-world" | "picking-location" | "lead-form" | "splash-screen" | "onboarding-instructions" | "loading-yard" | "help";

export const App: React.FC = function () {
    const [isSplashScreenOpen, setIsSplashScreenOpen] = useState(false);
    const [isOnboardingInstructionsOpen, setIsOnboardingInstructionsOpen] = useState(false);
    const [isLocationPickerOpen, setIsLocationPickerOpen] = useState(false);
    const [isLoadingWorld, setIsLoadingWorld] = useState(false);
    const [isLoadingYard, setIsLoadingYard] = useState(false);
    const [overlayState, setOverlayState] = useState<OverlayState>("default");
    const [isChangeLocationHidden, setIsChangeLocationHidden] = useState(false);
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [options, setOptions] = useState<IOptions>({
        farmSceneOptions: parseCurrentHash(), 
        setChangeLocationHidden: setIsChangeLocationHidden, 
        visibleButtons: {fullScreen: false, immersiveMode: false, vr: false},
        setVisibleButtons: (updatedButtons: IVisibleButtons) => {
            setOptions({...options, visibleButtons: updatedButtons})
        }
    });

    window.onhashchange = () => {
        setOptions( {...options, farmSceneOptions: parseCurrentHash()} );
    }

    useEffect(() => {
        let matches = document.cookie.match("splash_screen_shown");
        setIsSplashScreenOpen(!matches);
    }, [setIsSplashScreenOpen]);

    useEffect(() => {
        if (isSplashScreenOpen) setOverlayState("splash-screen");
        else if (isOnboardingInstructionsOpen) setOverlayState("onboarding-instructions");
        else if (isLoadingWorld) setOverlayState("loading-world");
        else if (isLocationPickerOpen) setOverlayState("picking-location");
        else if (isLoadingYard) setOverlayState("loading-yard");
        else if (isHelpOpen) setOverlayState("help");
        else setOverlayState("default");
    }, [isLocationPickerOpen, isLoadingWorld, isSplashScreenOpen, isOnboardingInstructionsOpen, isHelpOpen]);

    function dismissSplashScreen() {
        setIsSplashScreenOpen(false);
        setIsOnboardingInstructionsOpen(true);
        setIsLocationPickerOpen(true);
        let date = new Date();
        date.setFullYear(date.getFullYear() + 10);
        document.cookie = `splash_screen_shown=true; expires=${date.toUTCString()}`;
    }

    return (
        <div className={cn(styles.root, {isHelpOpen: isHelpOpen})}>
            <Options.Provider value={options}>
                <Header onHelp={() => setIsHelpOpen(true)} />
                <Toolbar handleEnquireNow={() => setOverlayState("lead-form")} isLoadingYard={isLoadingYard} />
                <Canvas setLoadingWorldState={setIsLoadingWorld} setLoadingYardState={setIsLoadingYard} />
                <Attribution />
                <Overlay open={overlayState !== "default"} showToolbar={isHelpOpen}>
                    {!isChangeLocationHidden && overlayState !== "splash-screen" && overlayState !== "onboarding-instructions" &&
                        <ChangeLocationButton
                            disabled={isLoadingWorld || isLoadingYard}
                            handleClick={() => setIsLocationPickerOpen(!isLocationPickerOpen)}
                            location={options.farmSceneOptions.location}
                            isLocationPickerOpen={overlayState === "picking-location"} />
                    }
                    <LoadingScreen open={overlayState === "loading-world"} />
                    <LocationPicker open={overlayState === "picking-location"} setOpen={setIsLocationPickerOpen} />
                    <LeadForm open={overlayState === "lead-form"} setClosed={() => setOverlayState("default")} />
                    <SplashScreen open={overlayState === "splash-screen"} setClosed={dismissSplashScreen} />
                    <OnboardingInstructions open={overlayState === "onboarding-instructions"} setOpen={setIsOnboardingInstructionsOpen} />
                    <Help open={overlayState === "help"} setOpen={setIsHelpOpen}/>
                </Overlay>
                
            </Options.Provider>
        </div>
    );
}
