import React, { useEffect, useState } from 'react';
import styles from './Overlay.module.scss';
import cn from 'classnames';

interface IOverlayProps {
    open: boolean;
    showToolbar: boolean;
}

export const Overlay: React.FC<IOverlayProps> = (props) => {

    let [showToolbar, setShowToolbar] = useState(false);

    useEffect(() => {
        if (props.showToolbar) {
            setShowToolbar(true);
        }
        else {
            // wait a while to resize so the user doesn't see the background
            // cover the toolbar while the transition is happening
            setTimeout(() => setShowToolbar(props.showToolbar), 500);
        }

    }, [props.showToolbar]);

    return (
        <>
            {showToolbar && <div className={styles.invisibleToolbarOverlay}></div>}
            <div className={cn(styles.root, { [styles.open]: props.open, [styles.showToolbar]: showToolbar })}>
                {props.children}
            </div>
        </>
    );
}
