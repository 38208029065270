// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2D8kRSDg-fUVIzpC5b1y4T{position:relative}@media(max-width: 628px){.largeFilterPanel ._3DzLvoDCk4D5lE0hAIfyt9{display:none}}.largeFilterPanel ._2D8kRSDg-fUVIzpC5b1y4T{border:none}.largeFilterPanel ._2D8kRSDg-fUVIzpC5b1y4T .items{max-height:inherit;border:0}.largeFilterPanel ._2D8kRSDg-fUVIzpC5b1y4T .item{margin-bottom:10px;border-radius:10px;border:1px solid var(--select-border)}", "",{"version":3,"sources":["webpack://./src/components/toolbar/brandSelect/BrandSelect.module.scss"],"names":[],"mappings":"AAEA,yBACI,iBAAA,CAIA,yBACI,2CACI,YAAA,CAAA,CAIR,2CACI,WAAA,CAEA,kDACI,kBAAA,CACA,QAAA,CAGJ,iDACI,kBAAA,CACA,kBAAA,CACA,qCAAA","sourcesContent":["@use '../../../breakpoints';\n\n.root {\n    position: relative;\n}\n\n:global(.largeFilterPanel) {\n    @media (max-width: breakpoints.$phone-l-small) {\n        .showFilters {\n            display: none;\n        }\n    }\n\n    .root {\n        border: none;\n\n        :global(.items) {\n            max-height: inherit;\n            border: 0;\n        }\n\n        :global(.item) {\n            margin-bottom: 10px;\n            border-radius: 10px;\n            border: 1px solid var(--select-border);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletSmall": "1000px",
	"root": "_2D8kRSDg-fUVIzpC5b1y4T",
	"showFilters": "_3DzLvoDCk4D5lE0hAIfyt9"
};
export default ___CSS_LOADER_EXPORT___;
