import React, { useContext, useEffect, useState } from 'react';
import { Options } from '../../../App';
import { ReactComponent as PhoneIcon } from '../../icons/smartphone.svg';

interface IImmersiveModeButtonProps {
    onToggleImmersiveMode: () => void;
}

export const ImmersiveModeButton: React.FC<IImmersiveModeButtonProps> = (props) => {
    const options = useContext(Options);
    const [showImmersiveModeButton, setShowImmersiveModeButton] = useState(false);

    useEffect(() => {
        if (typeof DeviceOrientationEvent === "function" && typeof DeviceOrientationEvent.requestPermission === "function") {
            setShowImmersiveModeButton(true);
        } else {
            window.addEventListener("devicemotion", function(event){
                if (event.rotationRate.alpha || event.rotationRate.beta || event.rotationRate.gamma)
                    setShowImmersiveModeButton(true);
            }, { once: true });
        }
    }, [setShowImmersiveModeButton]);

    useEffect(() => {
        options.setVisibleButtons({...options.visibleButtons, immersiveMode: showImmersiveModeButton});
    }, [showImmersiveModeButton, options.setVisibleButtons]);

    function toggleDeviceOrientationInput() {
        if (typeof DeviceOrientationEvent === "function" && typeof DeviceOrientationEvent.requestPermission === "function") {
            DeviceOrientationEvent.requestPermission()
                .then((result) => {
                    if (result === "granted") {
                        props.onToggleImmersiveMode();
                    } else if (result === "denied") {
                        window.alert("Please restart your browser if you want to enable immersive mode");
                    }
                });
        } else {
            props.onToggleImmersiveMode();
        }
    }

    return showImmersiveModeButton ? (
        <button onClick={toggleDeviceOrientationInput}>
            <PhoneIcon />
        </button>
    ) : null;
}
