import { ITileDefinition } from "../meshes/tile";
import { Scene, Texture, Vector2 } from "babylonjs";
import * as MapBox from "./mapbox"
import * as GMap from "./google"
import * as SECRETS from "../loaders/config"
import { isValidLocation } from "../geo/geo";
import { parseCurrentHash } from "../persistence";

function localTextureUrl(tileDef: { tileOffset: Vector2 }, style: "elevation" | "satellite" | "overlay" | "terrain") {
    let offset = `${Math.sign(tileDef.tileOffset.x)}_${Math.sign(tileDef.tileOffset.y)}`;
    return `Textures/DefaultLocation/${style}.${style === "satellite" || style === "overlay" ? "jpg" : "png"}`;
}

export function elevationUrl(tileDef: ITileDefinition) {
    if (!isValidLocation(parseCurrentHash().location)) {
        return localTextureUrl(tileDef, "elevation");
    }

    return MapBox.elevationDataUrl(tileDef.tile);
}

export function imageryUrl(tileDef: ITileDefinition) {
    if (!isValidLocation(parseCurrentHash().location)) {
        return localTextureUrl(tileDef, "satellite");
    }

    return SECRETS.googleMapApiKey
        ? GMap.googleSatellite(tileDef.tile)
        : MapBox.imageryUrl(tileDef.tile);
}

export function imageryOverlayUrl(tileDef: ITileDefinition) {
    if (!isValidLocation(parseCurrentHash().location)) {
        return localTextureUrl(tileDef, "overlay");
    }

    return SECRETS.googleMapApiKey
        ? GMap.googleSatelliteOverlay(tileDef.tile)
        : MapBox.imageryUrl(tileDef.tile);
}

export function imageryUrlCustom(lat: number, lng: number, zoom: number, size: string) {
    if (!isValidLocation(parseCurrentHash().location)) {
        return localTextureUrl({ tileOffset: new Vector2(0, 0) }, "satellite");
    }

    return GMap.googleSatelliteCustom(lat, lng, zoom, size);
}

export function terrainMapUrl(tileDef: ITileDefinition) {
    if (!isValidLocation(parseCurrentHash().location)) {
        return localTextureUrl(tileDef, "terrain");
    }

    return SECRETS.googleMapApiKey
        ? GMap.terrainUrl(tileDef.tile)
        : MapBox.terrainMapUrl(tileDef.tile);
}

export function loadTileTexture(scene: Scene, url: string, invertY: boolean): Promise<Texture> {
    let texture = new Promise<Texture>((resolve, reject) => {
        let texture = new Texture(url, scene, null, invertY, Texture.BILINEAR_SAMPLINGMODE, () => {
            texture.wrapU = 0; // clamp
            texture.wrapV = 0; // clamp
            resolve(texture);
        }, (msg, ex) => reject(msg));
    });

    return texture;
}