import React, { useEffect, useState } from 'react';
import { IFarmyardMetadata } from '../../../loaders/farmyardMetadata';
import { FancySelect } from '../fancySelect/FancySelect';

import '../fancySelect/fancy-select.scss';
import './working-capacity.scss';
import './slider.scss';

export interface IWorkingCapacitySlider {
    yards: IFarmyardMetadata[];
    selectedCapacity: number;
    setSelectedCapacity: (value: React.SetStateAction<number>) => void;
    disabled: boolean;
}

export const WorkingCapacitySlider: React.FC<IWorkingCapacitySlider> = (props) => {
    const [open, setOpen] = useState(false);
    const [minCapacity, setMinCapacity] = useState(0);
    const [maxCapacity, setMaxCapacity] = useState(0);

    useEffect(() => {
        const capacities = props.yards.filter(y => y.capacity).map(y => y.capacity).sort((a, b) => a - b);
        setMinCapacity(capacities[0]);
        setMaxCapacity(capacities[capacities.length - 1]);
        props.setSelectedCapacity(capacities[0]);
    }, [props.yards, setMinCapacity, setMaxCapacity, props.setSelectedCapacity]);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        props.setSelectedCapacity(parseFloat(event.currentTarget.value));
    }

    return (
        <div className="working-capacity">
            <FancySelect disabled={props.disabled} title="Working capacity" renderItem={<div><span className="count">{props.selectedCapacity}</span> Head</div>} open={open} setOpen={setOpen}>
                <ul className="items">
                    <li><input type="range" min={minCapacity} max={maxCapacity} step={5} value={props.selectedCapacity} onChange={handleChange}></input></li>
                </ul>
            </FancySelect>
        </div>
    );
}
